import React, { useState, useEffect } from 'react';
import { db } from '../Firebase.js';
import { 
  collection, 
  query, 
  orderBy, 
  onSnapshot, 
  updateDoc, 
  doc 
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const RemixGallery = () => {
  const [remixes, setRemixes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [updating, setUpdating] = useState({}); // Track updating state per remix
  const itemsPerPage = 10;

  const LoadingSpinner = () => (
    <div className="flex justify-center items-center">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
    </div>
  );

  useEffect(() => {
    const auth = getAuth();
    const allowedEmails = ['smgplank@gmail.com', 'jitsvm@gmail.com'];
    
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user && allowedEmails.includes(user.email)) {
        setIsAuthorized(true);
        const remixesRef = collection(db, 'remixes');
        const q = query(remixesRef, orderBy('queued_at', 'desc'));

        const unsubscribeRemixes = onSnapshot(q, (snapshot) => {
          const remixData = snapshot.docs
            .map(docSnap => ({
              id: docSnap.id,
              ...docSnap.data()
            }))
            .filter(remix => remix.completed_video_url);

          setRemixes(remixData);
          setLoading(false);
        });

        return () => unsubscribeRemixes();
      } else {
        setIsAuthorized(false);
        setLoading(false);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  // Helper function to update the document to make it public with feedback
  const makePublic = async (remix) => {
    setUpdating(prev => ({ ...prev, [remix.id]: true }));
    try {
      const remixRef = doc(db, 'remixes', remix.id);
      await updateDoc(remixRef, { public: true });
      // Firestore onSnapshot will update the remix.public field automatically
    } catch (err) {
      console.error("Error making public:", err);
    } finally {
      setUpdating(prev => ({ ...prev, [remix.id]: false }));
    }
  };

  const indexOfLastRemix = currentPage * itemsPerPage;
  const indexOfFirstRemix = indexOfLastRemix - itemsPerPage;
  const currentRemixes = remixes.slice(indexOfFirstRemix, indexOfLastRemix);
  const totalPages = Math.ceil(remixes.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderPaginationButtons = () => {
    const maxVisibleButtons = 5;
    const buttons = [];

    // Always show first page
    buttons.push(
      <button
        key={1}
        onClick={() => paginate(1)}
        className={`px-4 py-2 rounded ${
          currentPage === 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'
        }`}
      >
        1
      </button>
    );

    let startPage = Math.max(2, currentPage - Math.floor(maxVisibleButtons / 2));
    let endPage = Math.min(totalPages - 1, startPage + maxVisibleButtons - 3);
    startPage = Math.max(2, endPage - maxVisibleButtons + 3);

    if (startPage > 2) {
      buttons.push(<span key="ellipsis1" className="px-2">...</span>);
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => paginate(i)}
          className={`px-4 py-2 rounded ${
            currentPage === i ? 'bg-blue-500 text-white' : 'bg-gray-200'
          }`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages - 1) {
      buttons.push(<span key="ellipsis2" className="px-2">...</span>);
    }

    if (totalPages > 1) {
      buttons.push(
        <button
          key={totalPages}
          onClick={() => paginate(totalPages)}
          className={`px-4 py-2 rounded ${
            currentPage === totalPages ? 'bg-blue-500 text-white' : 'bg-gray-200'
          }`}
        >
          {totalPages}
        </button>
      );
    }

    return buttons;
  };

  const handleVideoPlay = (event) => {
    const videos = document.querySelectorAll('video');
    videos.forEach(video => {
      if (video !== event.target) {
        video.pause();
      }
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-32">
        <LoadingSpinner />
      </div>
    );
  }

  if (!isAuthorized) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-6">Access Denied</h1>
        <p>You do not have permission to view this gallery.</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">Remix Gallery</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {currentRemixes.map((remix) => (
          <div key={remix.id} className="bg-white rounded-lg shadow overflow-hidden">
            <div className="relative pb-[56.25%]">
              <video
                src={remix.completed_video_url}
                controls
                onPlay={handleVideoPlay}
                className="absolute inset-0 w-full h-full object-cover"
                style={{ backgroundColor: '#000' }}
              >
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="p-4">
              <p className="text-sm text-gray-500 mb-2">
                Created: {new Date(remix.queued_at).toLocaleString()}
              </p>
              <div className="mt-2 space-y-2">
                {remix.new_dialogue && remix.new_dialogue.map((dialogue, index) => (
                  <div key={index} className="text-sm">
                    <span className="font-semibold">{dialogue.speaker_id}: </span>
                    <span>{dialogue.text}</span>
                  </div>
                ))}
              </div>
              {/* Conditional rendering for the "Make Public" button */}
              {!remix.public ? (
                updating[remix.id] ? (
                  <p className="mt-4 text-center text-gray-600">Updating...</p>
                ) : (
                  <button
                    onClick={() => makePublic(remix)}
                    className="mt-4 w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Make Public
                  </button>
                )
              ) : (
                <p className="mt-4 text-center text-green-600 font-bold">Public</p>
              )}
            </div>
          </div>
        ))}
      </div>
      
      <div className="mt-6 flex justify-center gap-2">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 rounded bg-gray-200 disabled:opacity-50"
        >
          Previous
        </button>
        {renderPaginationButtons()}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 rounded bg-gray-200 disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default RemixGallery;
