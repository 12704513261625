import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getEndpoint } from '../utils';
import VideoCard from './VideoCard.jsx';
import { fetchPublicVideos } from "../Firebase.js";
import SearchBar from './SearchBar';

const PublicVideos = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastDocSnapshot, setLastDocSnapshot] = useState(null);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const observerRef = useRef();
  const searchTimeoutRef = useRef(null);
  const VIDEOS_PER_PAGE = 12;

  const lastVideoRef = useCallback(
    (node) => {
      if (loading) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreVideos();
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [loading, hasMore, lastDocSnapshot]
  );

  const handleSearch = (value) => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      setSearchTerm(value);
      setVideos([]);
      setLastDocSnapshot(null);
      setHasMore(true);
    }, 300);
  };

  const loadMoreVideos = async () => {
    try {
      setLoading(true);
      const { videos: newVideos, has_more, lastDocSnapshot: newLastDoc } = await fetchPublicVideos(
        VIDEOS_PER_PAGE,
        lastDocSnapshot,
        searchTerm
      );
      setVideos((prev) => [...prev, ...newVideos]);
      setHasMore(has_more);
      setLastDocSnapshot(newLastDoc);
    } catch (err) {
      console.error('Error fetching public videos:', err);
      setError('Failed to load videos. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMoreVideos();
  }, [searchTerm]);

  if (error && videos.length === 0) {
    return (
      <div className="mt-12 border-t border-gray-800 pt-2">
        <SearchBar onSearch={handleSearch} />
        <div className="px-4">
          <div className="flex flex-col items-center justify-center p-8">
            <p className="text-red-400 text-center mb-4">{error}</p>
            <button 
              onClick={() => {
                setError(null);
                setHasMore(true);
                loadMoreVideos();
              }}
              className="px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-md transition-colors"
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-2">
      <SearchBar onSearch={handleSearch} />
      <p className="text-xl font-bold text-gray-300 my-1">Recently created</p>

      {searchTerm.length > 0 && searchTerm.length < 3 && (
        <div className="flex flex-col items-center justify-center p-8 text-center">
          <p className="text-gray-400">
            Please enter at least 3 characters to search
          </p>
        </div>
      )}

      {!loading && videos.length === 0 && searchTerm.length >= 3 && (
        <div className="flex flex-col items-center justify-center p-8 text-center">
          <p className="text-gray-400">
            No videos found matching "{searchTerm}"
          </p>
        </div>
      )}

      {(videos.length > 0 || loading) && (
        <div className="grid grid-cols-2 md:grid-cols-1 lg:grid-cols- gap-6">
          {videos.map((video, index) => (
            <VideoCard
              key={video.id}
              video={video}
              isLastVideo={index === videos.length - 1}
              lastVideoRef={lastVideoRef}
            />
          ))}
        </div>
      )}

      {loading && (
        <div className="flex justify-center items-center p-4">
          <div className="w-10 h-10 rounded-full border-4 border-gray-700 border-t-green-400 animate-[spin_0.8s_ease-in-out_infinite]"></div>
        </div>
      )}
      
      {error && videos.length > 0 && (
        <div className="text-center p-4">
          <p className="text-red-400 mb-2">{error}</p>
          <button 
            onClick={() => {
              setError(null);
              loadMoreVideos();
            }}
            className="px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-md transition-colors"
          >
            Try Again
          </button>
        </div>
      )}

      {!hasMore && videos.length > 0 && !error && (
        <p className="text-gray-400 text-center p-4">No more videos to load</p>
      )}
    </div>
  );
};

export default PublicVideos;