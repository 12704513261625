import React, { useState, useEffect } from 'react';
import { Search, X } from 'lucide-react';

const PLACEHOLDER_TEXTS = ['Djokovic', 'Trump', 'Dune', 'Seinfeld'];
const TYPING_SPEED = 25;
const PAUSE_DURATION = 500;

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [currentCharIndex, setCurrentCharIndex] = useState(0);

  useEffect(() => {
    let timeout;
    
    if (isTyping) {
      if (currentCharIndex < PLACEHOLDER_TEXTS[currentTextIndex].length) {
        timeout = setTimeout(() => {
          setPlaceholder(PLACEHOLDER_TEXTS[currentTextIndex].slice(0, currentCharIndex + 1));
          setCurrentCharIndex(currentCharIndex + 1);
        }, TYPING_SPEED);
      } else {
        timeout = setTimeout(() => {
          setIsTyping(false);
        }, PAUSE_DURATION);
      }
    } else {
      if (currentCharIndex > 0) {
        timeout = setTimeout(() => {
          setPlaceholder(PLACEHOLDER_TEXTS[currentTextIndex].slice(0, currentCharIndex - 1));
          setCurrentCharIndex(currentCharIndex - 1);
        }, TYPING_SPEED);
      } else {
        timeout = setTimeout(() => {
          setCurrentTextIndex((currentTextIndex + 1) % PLACEHOLDER_TEXTS.length);
          setIsTyping(true);
        }, TYPING_SPEED);
      }
    }

    return () => clearTimeout(timeout);
  }, [currentCharIndex, currentTextIndex, isTyping]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  const handleClear = () => {
    setSearchTerm('');
    onSearch('');
  };

  return (
    <div className="w-full px-4 mb-6">
      <div className="relative max-w-screen-lg mx-auto w-full lg:w-1/2">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder={`Search for ${placeholder}`}
          className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg 
                     focus:outline-none focus:border-green-500 transition-colors
                     text-gray-100 placeholder-gray-400"
        />
        <div className="absolute right-3 top-1/2 transform -translate-y-1/2 flex items-center gap-2">
          {searchTerm && (
            <button
              onClick={handleClear}
              className="text-gray-400 hover:text-gray-300 transition-colors"
            >
              <X className="h-4 w-4" />
            </button>
          )}
          <Search className="h-5 w-5 text-gray-400" />
        </div>
      </div>
    </div>
  );
};

export default SearchBar;