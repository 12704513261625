import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInAnonymously } from 'firebase/auth';
import { auth } from '../Firebase.js';
import { useUser } from '../contexts/UserContext';
import { db } from '../Firebase.js';
import {
  collection,
  query,
  orderBy,
  getDocs,
  limit,
  where,
  startAfter,
  onSnapshot,
  doc
} from 'firebase/firestore';
import { useMediaQuery } from 'react-responsive';
import LazyVideo from './LazyVideo';
import InfiniteScroll from 'react-infinite-scroll-component';
import PublicVideos from './PublicVideos';
import SearchBar from './SearchBar';


import { useParams } from 'react-router-dom';
import { getEndpoint } from '../utils';
import RelatedVideos from './RelatedVideos';
import SceneLibrary from './SceneLibrary';

import { logEvent } from "../utils";



const RemixEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const videoRef = React.useRef(null);
  const { currentUser } = useUser();
  const username = currentUser?.email || currentUser?.uid;
  const [scene, setScene] = useState(null);
  const [lines, setLines] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [docExists, setDocExists] = useState(false);

  const CHARACTER_LIMIT = 300;

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, 'remix-scenes-stock', id),
      (docSnap) => {
        setLoading(false);

        if (!docSnap.exists()) {
          setDocExists(false);
          return;
        }

        setDocExists(true);
        const sceneData = { id: docSnap.id, ...docSnap.data() };
        setScene(sceneData);

        if (sceneData.scene) {
          const initialLines = sceneData.scene.map((segment) => ({
            speaker_id: segment.speaker_id,
            duration: segment.end - segment.start,
            original_text: segment.original_text,
            text: '',
          }));
          setLines(initialLines);
        }
      },
      (error) => {
        console.error('Firestore onSnapshot error:', error);
        setLoading(false);
        setDocExists(false);
      }
    );

    return () => unsubscribe();
  }, [id]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await logEvent({
        eventName: 'remix-submit-remix',
        sceneId: scene.id,
        title: scene.title,
        user: username,
      });

      const response = await fetch(`${getEndpoint()}/remix-scene`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          scene_document: scene,
          lines: lines,
        }),
      });

      if (response.ok) {
        const { document_id } = await response.json();
        navigate(`/mix/${document_id}`);
      }
    } catch (error) {
      console.error('Error submitting remix:', error);
    }
    setIsSubmitting(false);
  };

  const getPlaceholder = (line) => {
    if (line.original_text) {
      return `${line.original_text.slice(
        0,
        100 + line.original_text.slice(100).indexOf(' ')
      )}...`;
    } else {
      return 'Enter text';
    }
  };

  const handleTextChange = (index, value) => {
    const newLines = [...lines];
    // Limit the text to CHARACTER_LIMIT characters
    newLines[index].text = value.slice(0, CHARACTER_LIMIT);
    setLines(newLines);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-black flex flex-col items-center justify-center gap-4">
        <p className="text-gray-400 text-lg">Loading…</p>
      </div>
    );
  }

  if (!docExists) {
    return (
      <div className="min-h-screen bg-black flex flex-col items-center justify-center gap-4">
        <p className="text-gray-400 text-lg">
          Hm, we couldn't find a remix with that ID
        </p>
        <a
          href="https://www.eggnog.ai/remix"
          className="text-green-400 hover:text-green-300 transition-colors"
        >
          Go home
        </a>
      </div>
    );
  }

  if (!scene?.scene) {
    return (
      <div className="min-h-screen bg-black flex flex-col items-center justify-center gap-4">
        <p className="text-gray-400 text-lg">Hm, we couldn't find a remix</p>
        <a
          href="https://www.eggnog.ai/remix"
          className="text-green-400 hover:text-green-300 transition-colors"
        >
          Go home
        </a>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white">
      <header className="sticky top-0 bg-black/90 backdrop-blur-sm p-4 z-10">
        <h1 className="text-2xl font-bold text-center">🥚 remix </h1>
        <h2 className="text-md text-center text-gray-400 mt-2">
          Remix popular movie scenes, TV shows, and current events ✨
        </h2>
      </header>

      <div className="max-w-4xl mx-auto p-4">
        <button
          onClick={() => navigate('/remix')}
          className="text-gray-400 hover:text-white transition my-4"
        >
          ← Back to all scenes
        </button>
        <div className="mb-8">
          <video
            ref={videoRef}
            src={scene.video_url}
            controls
            autoPlay
            muted
            playsInline // keep the video inline on iOS
            onLoadedData={() => {
              if (videoRef.current) {
                // Setting volume to 1 won't have an effect until the user
                // interacts if the video is muted for iOS autoplay
                videoRef.current.volume = 1;
              }
            }}
            className="w-full rounded-lg shadow-lg border-2 border-gray-700"
          />
        </div>
        <h1 className="text-xl font-bold my-4">New Remix script</h1>
        <div className="space-y-4">
          {lines.map((line, index) => (
            <div
              key={index}
              className="bg-gray-900 p-4 rounded-lg border border-gray-800 transition-all hover:border-gray-700"
            >
              <div className="flex justify-between mb-2">
                <span className="text-gray-400">
                  {line.speaker_id
                    .split(/[_-]/)
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(' ')}
                </span>
                <span className="text-gray-400">{line.duration.toFixed(1)}s</span>
              </div>
              <input
                type="text"
                value={line.text}
                onChange={(e) => handleTextChange(index, e.target.value)}
                className="w-full p-3 rounded-lg bg-gray-800 border border-gray-700 focus:border-gray-500 focus:outline-none transition"
                placeholder={getPlaceholder(line)}
              />
              {line.text.length >= CHARACTER_LIMIT && (
                <p className="text-red-500 text-xs mt-1">Maximum character limit reached</p>
              )}
            </div>
          ))}
        </div>

        <button
          onClick={handleSubmit}
          disabled={isSubmitting || lines.some(line => !line.text)}
          className="my-6 w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 disabled:bg-gray-700 disabled:cursor-not-allowed transition-colors duration-200 font-bold"
        >
          {isSubmitting ? 'Creating...' : 'Create Remix'}
        </button>
      <RelatedVideos originalSceneId={id} videoId={''} originalSceneTitle={scene.title} showMakeOne={false}/>
      </div>
    </div>
  );
};

export default RemixEditor;