import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import { FaSyncAlt, FaVideo, FaInfinity } from "react-icons/fa"
import { GiByzantinTemple } from "react-icons/gi";

const products = [
  {
    name: "Time Portal",
    description: "Travel through time, guess where you are",
    url: "/entertimeportal",
    icon: GiByzantinTemple,
    color: "text-purple-400",
  },
  {
    name: "Remix",
    description: "Remix movie and TV show clips",
    url: "/remix",
    icon: FaSyncAlt,
    color: "text-red-400",
  },
  {
    name: "Editor",
    description: "AI video editor",
    url: "/playground",
    icon: FaVideo,
    color: "text-green-400",
  },
  {
    name: "Infinite",
    description: "Watch infinite TV",
    url: "/infinite",
    icon: FaInfinity,
    color: "text-yellow-400",
  },
]

export default function LandingPage() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const navigate = useNavigate()

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY })
    }

    window.addEventListener("mousemove", handleMouseMove)

    return () => {
      window.removeEventListener("mousemove", handleMouseMove)
    }
  }, [])


  return (
    <div className="relative font-mono flex flex-col items-center justify-between min-h-screen bg-black text-white overflow-hidden p-8">
      {/* Animated Background Gradient */}
      <motion.div
        className="absolute inset-0 z-0"
        animate={{
          background: [
            "radial-gradient(circle at 0% 0%, #ffffff10 0%, #00000000 50%)",
            "radial-gradient(circle at 100% 100%, #ffffff10 0%, #00000000 50%)",
            "radial-gradient(circle at 100% 0%, #ffffff10 0%, #00000000 50%)",
            "radial-gradient(circle at 0% 100%, #ffffff10 0%, #00000000 50%)",
          ],
        }}
        transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
      />

      {/* Animated Border */}
      <motion.div
        className="absolute inset-0 border-8 border-gray-800 z-20 pointer-events-none"
        animate={{
          borderWidth: ["8px", "12px", "8px"],
          borderColor: ["#1a1a1a", "#2a2a2a", "#1a1a1a"],
        }}
        transition={{ duration: 5, repeat: Infinity, ease: "easeInOut" }}
      />


      {/* Title and Subheader */}
      <div className="z-10 text-center">
        <h1 className="text-4xl md:text-6xl font-bold mb-2">
          <motion.span
            className="inline-block"
            animate={{ rotate: [0, 5, 0, -5, 0] }}
            transition={{ duration: 5, repeat: Infinity }}
          >
            e
          </motion.span>
          ggnog.ai
        </h1>
        <h2 className="text-md text-gray-400 m-8">
          Select a video experience below
        </h2>
      </div>

      {/* Product Grid with Glassy Boxes */}
      <div className="grid grid-cols-2 gap-4 w-full max-w-3xl z-10">
        {products.map((product, index) => {
          const Icon = product.icon
          return (
            <div 
              key={index} 
              onClick={() => navigate(product.url)}
              className="aspect-square cursor-pointer"
            >
              <motion.div
                className="relative h-full p-4 rounded-lg bg-white/10 backdrop-blur-md flex flex-col justify-between border border-white/20"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <h2 className="text-xl font-bold">{product.name}</h2>
                <p className="text-sm text-gray-400 mt-2 w-3/4">{product.description}</p>
                <Icon className={`absolute bottom-2 right-2 ${product.color}`} size={24} />
              </motion.div>
            </div>
          )
        })}
      </div>

      {/* Footer */}
      <footer className="text-center text-sm text-gray-500 z-10 mt-8">
        <p>© 2024 Eggnog. All rights reserved.</p>
        <div className="mt-2">
          <span
            onClick={() => navigate("/privacy-policy")}
            className="hover:text-gray-300 transition-colors duration-200 ease-in-out mr-4 cursor-pointer"
          >
            Privacy Policy
          </span>
          <span
            onClick={() => navigate("/eula-policy")}
            className="hover:text-gray-300 transition-colors duration-200 ease-in-out cursor-pointer"
          >
            EULA
          </span>
        </div>
      </footer>
    </div>
  )
}
