import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { db, auth } from './Firebase';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { FiShare } from 'react-icons/fi';
import { useUser } from './contexts/UserContext';
import { signInAnonymously } from 'firebase/auth';

const TimePortalResults = () => {
    const { currentUser, loading: userLoading } = useUser();
    const username = currentUser?.email || currentUser?.uid;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Now read gameId, sessionId, and firstRound from the search params instead of useParams()
    const gameId = searchParams.get('gameId');
    const sessionId = searchParams.get('sessionId');
    const firstRoundParam = searchParams.get('firstRound') || '';

    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState(null);
    const [error, setError] = useState(null);
    const [copied, setCopied] = useState(false);
    const [showScoreInfo, setShowScoreInfo] = useState(false);
    const [firstRounds, setFirstRounds] = useState([]);

    const trackEvent = async (eventName, eventData) => {
        const data = {
            user: username,
            event_name: eventName,
            manufacturer: 'Web',
            ...eventData,
        };

        fetch('https://timetravel-dd209e3dce1f.herokuapp.com/log', {
        // fetch('http://127.0.0.1:2999/log', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((data) => {
            console.log('Success:', data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    useEffect(() => {
        if (!userLoading && results) {
            trackEvent('timeportal_results_view', {
                game_id: gameId,
                session_id: sessionId,
                total_score: results.totalScore,
                rounds: results.rounds
            });
        }
    }, [userLoading, results, gameId, sessionId]);

    useEffect(() => {
        const fetchResults = async () => {
            try {
                // Guard: if no gameId is in the query, stop here
                if (!gameId) {
                    throw new Error('Missing gameId in query parameters.');
                }

                // Pull the document using gameId from search params
                const docRef = doc(db, 'timetravel-gamesessions', gameId);
                const docSnap = await getDoc(docRef);
                
                if (!docSnap.exists()) {
                    throw new Error('Game not found');
                }

                const data = docSnap.data();
                
                // If this is not the first round but we have a known first_round ID, go there
                if (data.round_number !== 1 && data.first_round) {
                    navigate(`/timeportal/${data.first_round}?sessionId=${sessionId}`);
                    return;
                }
                
                // Check if all 5 rounds are complete
                const rounds = [
                    data.round_1_score,
                    data.round_2_score,
                    data.round_3_score,
                    data.round_4_score,
                    data.round_5_score
                ];

                if (rounds.some(score => score === undefined)) {
                    throw new Error('Whoops, something went wrong! This is an incomplete game');
                }

                const totalScore = rounds.reduce((sum, score) => sum + score, 0);
                setResults({
                    rounds,
                    totalScore,
                    created_at: data.created_at,
                    updated_at: data.updated_at,
                    portal_1_id: data.portal_1_id
                });
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchResults();
    }, [gameId, navigate, sessionId]);

    useEffect(() => {
        const fetchFirstRounds = async () => {
            try {
                const challengesRef = collection(db, 'timetravel');
                const querySnapshot = await getDocs(challengesRef);
                const now = new Date();
                
                const validChallenges = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(challenge => 
                        challenge.daily_challenge_date && 
                        challenge.daily_challenge_date.toDate() <= now &&
                        challenge.status === "COMPLETE"
                    )
                    .sort((a, b) => 
                        b.daily_challenge_date.toDate() - a.daily_challenge_date.toDate()
                    );

                setFirstRounds(validChallenges.map(challenge => challenge.id));
            } catch (error) {
                console.error('Error fetching challenges:', error);
            }
        };

        fetchFirstRounds();
    }, []);

    const handleShare = async () => {
        const shareUrl = sessionId 
            ? `${window.location.origin}/entertimeportal?sessionId=${sessionId}`
            : `${window.location.origin}/entertimeportal`;

        try {
            await navigator.clipboard.writeText(shareUrl);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);

            // Track share event
            trackEvent('timeportal_results_share', {
                game_id: gameId,
                session_id: sessionId,
                total_score: results?.totalScore
            });
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    const handleDownload = () => {
        let token;
        if (results.totalScore >= 40000) {
            token = "a86359fe-2eeb-4cc3-bbea-ce6780a05ee0"; // gold
        } else if (results.totalScore >= 30000) {
            token = "5872287c-26f3-45d5-b39f-eb20c9c71741"; // silver
        } else {
            token = "7ba9968f-5454-4c33-9b1d-d95aca1623b6"; // bronze
        }
        
        // Track download event
        trackEvent('timeportal_trophy_download', {
            game_id: gameId,
            session_id: sessionId,
            total_score: results.totalScore,
            trophy_type: results.totalScore >= 40000 ? 'gold' : results.totalScore >= 30000 ? 'silver' : 'bronze'
        });
        
        const proxyUrl = `https://timetravel-dd209e3dce1f.herokuapp.com/proxy-video/${token}`;
        window.location.href = proxyUrl;
    };

    const handleKeepPlaying = () => {
        trackEvent('timeportal_keep_playing', {
            game_id: gameId,
            session_id: sessionId,
            total_score: results?.totalScore,
            next_portal: nextFirstRoundParam
        });
        
        navigate(`/timeportal/${nextFirstRoundParam}${sessionId ? `?sessionId=${sessionId}` : ''}`);
    };

    useEffect(() => {
        const checkAndSignInAnonymously = async () => {
            if (!currentUser && !userLoading) {
                try {
                    await signInAnonymously(auth);
                } catch (error) {
                    console.error('Error during anonymous sign-in:', error);
                }
            }
        };

        checkAndSignInAnonymously();
    }, [currentUser, userLoading]);

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-black">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex flex-col items-center justify-center bg-black text-white">
                <div className="text-xl mb-4">{error}</div>
                <button
                    onClick={() => navigate('/')}
                    className="px-4 py-2 bg-blue-500 rounded hover:bg-blue-600"
                >
                    Return Home
                </button>
            </div>
        );
    }

    // Safely handle firstRoundParam within the array
    const currentIndex = firstRounds.indexOf(firstRoundParam);
    console.log("currentIndex", currentIndex);
    // If not found or it's the last one, default back to the first round. Otherwise go to next
    const nextFirstRoundParam = (currentIndex !== -1 && currentIndex < firstRounds.length - 1)
        ? firstRounds[currentIndex + 1]
        : firstRounds[0];

    console.log("nextFirstRoundParam", nextFirstRoundParam);

    return (
        <div className="min-h-screen bg-black text-white p-4 sm:p-8">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-3xl sm:text-4xl font-bold text-center mb-6 sm:mb-8 uppercase tracking-wider" style={{
                    textShadow: '0 0 5px #4682B4, 0 0 10px #4682B4'
                }}>Final Results</h1>
                
                <div className="bg-gray-900 rounded-lg p-4 sm:p-8 mb-6 sm:mb-8">
                    <div className="text-center mb-6 sm:mb-8">
                        <div className="text-4xl sm:text-6xl font-bold mb-3 sm:mb-4" style={{
                            color: '#22c55e',
                            textShadow: '0 0 5px #22c55e, 0 0 10px #22c55e',
                            textTransform: 'uppercase',
                            letterSpacing: '0.15em'
                        }}>
                            {results.totalScore.toLocaleString()}
                        </div>
                        <div className="text-lg sm:text-xl text-gray-400 uppercase tracking-wider mb-6 relative text-center">
                            <div className="inline-flex items-center">
                                Total Points out of 50,000
                                <button 
                                    className="ml-2 text-sm bg-gray-700 rounded-full w-5 h-5 inline-flex items-center justify-center hover:bg-gray-600"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowScoreInfo(prev => !prev);
                                    }}
                                >
                                    ?
                                </button>
                            </div>
                            {showScoreInfo && (
                                <div className="absolute z-50 bg-gray-800 text-white p-4 rounded-lg shadow-lg w-[280px] sm:w-[320px] text-sm left-1/2 transform -translate-x-1/2 mt-2">
                                    <button 
                                        className="absolute top-2 right-2 text-gray-400 hover:text-white"
                                        onClick={() => setShowScoreInfo(false)}
                                    >
                                        ✕
                                    </button>
                                    <p className="mb-2">Your score is calculated based on two factors:</p>
                                    <p className="mb-2">• Location (5,000 points): Full points if within 10km, decreases with distance</p>
                                    <p>• Time (5,000 points): Full points if exact year, decreases with time difference</p>
                                </div>
                            )}
                        </div>

                        <div className="flex flex-col sm:flex-row justify-center gap-4 mb-6">
                            <button
                                onClick={handleKeepPlaying}
                                className="px-6 sm:px-8 py-4 bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] uppercase tracking-wider relative overflow-hidden text-center text-lg sm:text-xl font-bold animate-pulse"
                                style={{
                                    textShadow: '0 0 5px #4682B4, 0 0 10px #4682B4, 0 0 15px #4682B4',
                                    boxShadow: '0 0 10px #4682B4',
                                }}
                            >
                                Keep Playing
                            </button>
                            <button
                                onClick={handleShare}
                                className="px-4 sm:px-6 py-3 bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] uppercase tracking-wider flex items-center justify-center gap-2 relative overflow-hidden"
                                style={{
                                    textShadow: '0 0 5px #4682B4, 0 0 10px #4682B4'
                                }}
                            >
                                {copied ? (
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                        </svg>
                                        URL Copied!
                                    </>
                                ) : (
                                    <>
                                        <FiShare className="h-5 w-5" />
                                        Challenge a friend
                                    </>
                                )}
                            </button>
                        </div>

                        {results.totalScore >= 20000 && (
                            <>
                                <video 
                                    autoPlay 
                                    muted 
                                    className="mt-4 rounded-lg mx-auto"
                                    style={{ maxWidth: '100%' }}
                                >
                                    <source 
                                        src={
                                            results.totalScore >= 40000
                                                ? "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/tmpkbs94f9ptrophy_video_out.mp4?alt=media&token=a86359fe-2eeb-4cc3-bbea-ce6780a05ee0"
                                                : results.totalScore >= 30000
                                                ? "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/silver_trophy.mp4?alt=media&token=5872287c-26f3-45d5-b39f-eb20c9c71741"
                                                : "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/bronze_trophy.mp4?alt=media&token=7ba9968f-5454-4c33-9b1d-d95aca1623b6"
                                        }
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                                <div className="mt-4 text-lg text-center text-[#22c55e]" style={{
                                    textShadow: '0 0 5px #22c55e, 0 0 10px #22c55e'
                                }}>
                                    {results.totalScore >= 40000
                                        ? "You won a gold trophy! We won't blame you if you want to show it off..."
                                        : results.totalScore >= 30000
                                        ? "You won a silver trophy! We won't blame you if you want to show it off..."
                                        : "You won a bronze trophy! We won't blame you if you want to show it off..."
                                    }
                                </div>
                                <button 
                                    onClick={handleDownload}
                                    className="mt-3 inline-block px-4 py-2 bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4]"
                                    style={{
                                        textShadow: '0 0 5px #4682B4, 0 0 10px #4682B4'
                                    }}
                                >
                                    Claim Trophy
                                </button>
                            </>
                        )}

                        <div className="text-center mt-8">
                            <p className="text-lg text-gray-300 mb-4">The app is coming soon! Join our Discord for updates</p>
                            <a 
                                href="https://discord.gg/NsYP9xFhFe" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="inline-flex items-center bg-[#5865F2] hover:bg-[#4752C4] text-white font-semibold px-4 py-3 rounded-lg transition-colors duration-200"
                            >
                                <img 
                                    src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/discord_white.png?alt=media&token=bc96f6d2-ebd5-44ba-87fc-4db30b2258d9" 
                                    className="mr-2 h-5 w-6" 
                                    alt="Discord logo" 
                                />
                                Join Discord
                            </a>
                        </div>
                    </div>
                </div>

                <div className="bg-gray-900 rounded-lg p-4 sm:p-8">
                    <h2 className="text-2xl font-bold text-center mb-6 uppercase tracking-wider" style={{
                        textShadow: '0 0 5px #4682B4, 0 0 10px #4682B4'
                    }}>Score Breakdown</h2>
                    <div className="grid gap-3 sm:gap-4">
                        {results.rounds.map((score, index) => (
                            <div 
                                key={index}
                                className="bg-gray-800 p-3 sm:p-4 rounded-lg flex justify-between items-center"
                            >
                                <div className="text-base sm:text-lg uppercase tracking-wider" style={{
                                    textShadow: '0 0 5px #4682B4, 0 0 10px #4682B4'
                                }}>Round {index + 1}</div>
                                <div className="text-lg sm:text-xl font-semibold" style={{
                                    color: '#22c55e',
                                    textShadow: '0 0 5px #22c55e, 0 0 10px #22c55e'
                                }}>
                                    {score.toLocaleString()} pts
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TimePortalResults; 