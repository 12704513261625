import { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import LandingPage from './LandingPage/';
import Playground from './Playground/';
import PrivacyPolicyPage from './UserAgreements/PrivacyPolicyPage';
import EULAPolicyPage from './UserAgreements/EULAPolicy';

import LoginPage from './Login/';
import InfiniteLoginPage from "./Login/InfiniteLoginPage.jsx";
import ProfilePage from './ProfilePage/';
import CreateCharacter from './CreateCharacter/';
import UploadCharacter from './UploadCharacter/';
import ResultPage from './ResultPage/';
import ComposeVideo from './ComposeVideo/';
import InfiniteLandingPage from './InfiniteWatch/landing-page.jsx';
import InfiniteView from "./InfiniteWatch/index.jsx";
import InfiniteViewAvailable from "./InfiniteWatch/InfiniteViewAvailable.jsx";
import MemeMaker from './MemeMaker'
import SceneRemix from './SceneRemix'
import MixView from './SceneRemix/MixView.jsx'
import HpLandingPage from "./LandingPage/HpLandingPage";
import JpLandingPage from "./LandingPage/JpLandingPage";

import ShowSubmissionsViewer from "./Admin/ShowSubmissionsViewer";
import TextEpisodeViewer from './Admin/TextEpisodeViewer';
import EpisodeTimeline from "./Admin/EpisodeTimeline.jsx";
import DownloadFromTiktok from "./DownloadFromTiktok/";
import RemixGallery from "./Admin/RemixGallery";

import { UserProvider, useUser } from './contexts/UserContext.jsx';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './Firebase.js'
import TimePortal from './TimePortal';
import TimePortalCreator from './TimePortalCreator';
import TimePortalResults from './TimePortalResults';
import EnterTimePortal from './EnterTimePortal';

const shows = [
  { id: 'a68dae60-3a7e-4400-93cd-bd09e57814c3', title: 'Stranger Things: Lost Lucas'},
  { id: '191ab1ee-026a-4439-8471-802f4344cfbf', title: 'Breaking Bad: Maldives'},
  { id: 'c07b2004-0bfe-4e2c-983d-9c5f317751ae', title: "Epstein's Escape"},
  { id: '94b0b59f-6889-408a-a31a-42a42d55eb73', title: 'Bridgerton'},
  { id: '5c6e62d9-b268-4c08-90ee-3eb7cc257052', title: 'The Illuminati'},
  { id: 'YgyCdyw2GtVgXtvsS5qB', title: 'Sherlock Holmes' },
  { id: 'UjvK5nk3gxY2qSsT81XJ', title: 'Triangle of Passion' },
  { id: 'f41c0807-bfde-4099-9c32-43216ec27f17', title: 'The Pauls: Media Glory'},  
  { id: '3755b4bc-c385-4d41-b957-91179ec428a0', title: 'Cubicle'},
  { id: '003deefb-c968-481f-979d-b608c887f941', title: 'Love Lagoon'},
  { id: 'eaf6252b-b57a-42d4-aad3-96df5081e68e', title: 'Sex Education: Oxford'},
  { id: 'bdda399b-24cc-49d5-adc5-ff84f75c1abd', title: 'Euphoria: Rue\'s Disappearance'},
];





function App() { 
  return (
    <UserProvider> {/* Wrap the entire app with UserProvider */}
      <Router>
        <Routes >
          <Route path="/" element={<LandingPage />} />
          <Route path="/profile/:specialState?" element={ <ProfilePage /> }/>
          <Route path="/login/:specialState?" element={<LoginPage />} />
          <Route path="/infinite-login/:episodeId" element={<InfiniteLoginPage />} />
          <Route path="/playground/:specialState?" element={<Playground />} />
          <Route path="/compose/:specialState?" element={ <ComposeVideo /> }/>
          <Route path="/create/:specialState?" element={<CreateCharacter />} />
          <Route path="/upload/:specialState?" element={<UploadCharacter />} />
          <Route path="/result/:specialState?/:resultId?" element={<ResultPage />} />
          <Route path="/infinite" element={<InfiniteLandingPage shows={shows} />} />
          <Route path="/infinite-show/:storyId?" element={<InfiniteView shows={shows}/>} />
          <Route path="/infinite-show-available/:storyId?" element={<InfiniteViewAvailable shows={shows}/>} />
          <Route path="/mememaker" element={< MemeMaker />}/>
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/eula-policy" element={<EULAPolicyPage />} />
          <Route path="/hp" element={<HpLandingPage />} />
          <Route path="/pookie" element={<JpLandingPage />} />
          <Route path="/submissions" element={< ShowSubmissionsViewer />}/>
          <Route path="/downloadfromtiktok" element={< DownloadFromTiktok />}/>
          <Route path="/text-episode-viewer/:episodeId" element={<TextEpisodeViewer />} />
          <Route path="/episode-timeline/:episodeId" element={<EpisodeTimeline />} />
          <Route path="/timeportal/:id" element={<TimePortal />} />
          <Route path="/timeportalcreate" element={<TimePortalCreator />} />
          <Route path="/timeportalresults" element={<TimePortalResults />} />
          <Route path="/entertimeportal" element={<EnterTimePortal />} />
          <Route path="/remix/:id" element={<SceneRemix />} />
          <Route path="/remix/" element={<SceneRemix />} />
          <Route path="/mix/:document_id/" element={<MixView />} />
          <Route path="/remix-gallery" element={<RemixGallery />} />

        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
