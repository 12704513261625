import React, { useEffect, useRef, useState } from 'react';
import { LuX } from "react-icons/lu";
import { format } from 'date-fns';
import { logEvent } from "../utils";
import { auth } from '../Firebase.js';
import { signInAnonymously } from 'firebase/auth';
import { useUser } from '../contexts/UserContext';


const VideoModal = ({ video, onClose }) => {
  const modalRef = useRef();
  const videoRef = useRef();
  const [copied, setCopied] = useState(false);
  const { currentUser, loading } = useUser();
  const username = currentUser?.email || currentUser?.uid;

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') onClose();
    };

    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) onClose();
    };

    document.addEventListener('keydown', handleEscape);
    document.addEventListener('mousedown', handleClickOutside);

    if (videoRef.current) videoRef.current.play();

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    const checkAndSignInAnonymously = async () => {
      if (!currentUser && !loading) {
        try {
          await signInAnonymously(auth);
        } catch (error) {
          console.error('Error during anonymous sign-in:', error);
        }
      }
    };

    checkAndSignInAnonymously();
  }, [currentUser, loading]);

  const shareUrl = `www.eggnog.ai/mix/${video.id}`;

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
      await logEvent({
        eventName: 'remix-copy-link-from-video-modal',
        user: username,
        video: shareUrl,
      });
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-4 md:p-6">
      <div 
        ref={modalRef}
        className="bg-gray-900 rounded-xl w-full max-w-5xl mx-auto overflow-hidden shadow-2xl relative flex flex-col max-h-[90vh]"
      >
        {/* Close button - now positioned absolutely relative to modal */}
        <button
          onClick={onClose}
          className="absolute right-4 top-4 z-10 p-2 hover:bg-black/20 rounded-full transition-colors"
        >
          <LuX className="w-6 h-6 text-white" />
        </button>

        {/* Video container with proper aspect ratio */}
        <div className="w-full pt-[56.25%] relative bg-black">
          <video
            ref={videoRef}
            src={video.video_url}
            className="absolute inset-0 w-full h-full object-contain"
            controls
            autoPlay
          />
        </div>

        {/* Content section */}
        <div className="p-4 md:p-6 space-y-6 flex-shrink-0">
          {/* Creator info and remix button */}
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
            <div className="space-y-1">
              <h3 className="text-gray-200 font-medium">
                Created by {video.creator_name || 'Anonymous'}
              </h3>
              <p className="text-gray-500 text-sm">
                {video.completed_at && format(new Date(video.completed_at), 'MMMM d, yyyy')}
              </p>
            </div>
            
            <button
              onClick={async () => {
                // Open the URL in a new tab
                window.open(`https://www.eggnog.ai/remix/${video.original_scene_id}`, '_blank', 'noopener,noreferrer');
                // Log the event
                await logEvent({
                  eventName: 'remix-new-remix-from-video',
                  user: username,
                  originalSceneId: video.original_scene_id,
                  video_url: `https://www.eggnog.ai/remix/${video.original_scene_id}`,
                });
              }}
              className="px-6 py-2.5 bg-green-400 hover:bg-green-500 text-black font-medium rounded-lg transition-colors text-center whitespace-nowrap"
            >
              Remix this scene →
            </button>

          </div>

          {/* Share section */}
          <div className="space-y-2">
            <p className="text-gray-400 font-medium">Share this remix:</p>
            <div className="bg-gray-800 p-3 rounded-lg relative">
              <div className="flex items-center gap-3 flex-wrap sm:flex-nowrap">
                <code className="text-sm text-gray-300 break-all sm:break-normal sm:truncate flex-1 min-w-0">
                  {shareUrl}
                </code>
                <button
                  onClick={handleCopyLink}
                  className={`
                    px-4 py-2 rounded-md flex-shrink-0
                    transition-all duration-300 
                    ${copied 
                      ? 'bg-green-400 text-black' 
                      : 'bg-gray-700 hover:bg-gray-600 text-gray-200'
                    }
                  `}
                >
                  {copied ? 'Copied!' : 'Copy'}
                </button>
              </div>

              {/* Success message overlay */}
              {copied && (
                <div className="absolute inset-0 bg-green-400/10 backdrop-blur-sm flex items-center justify-center rounded-lg">
                  <div className="flex items-center gap-2 text-green-400">
                    <span>✓</span>
                    <span>Copied</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;