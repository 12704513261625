import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const TimePortalCreator = () => {
    const [rounds, setRounds] = useState([
        { eventName: '', locationName: '', year: 2000 },
        { eventName: '', locationName: '', year: 2000 },
        { eventName: '', locationName: '', year: 2000 },
        { eventName: '', locationName: '', year: 2000 },
        { eventName: '', locationName: '', year: 2000 }
    ]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const [isHoldingLeft, setIsHoldingLeft] = useState(false);
    const [isHoldingRight, setIsHoldingRight] = useState(false);
    const [dailyChallengeDate, setDailyChallengeDate] = useState(new Date().toISOString().split('T')[0]);
    const [isDragging, setIsDragging] = useState(false);

    const formatYear = (year) => {
        return year < 0 ? Math.abs(year) + " BC" : year + " AD";
    };

    const handleRoundChange = (index, field, value) => {
        const newRounds = [...rounds];
        newRounds[index] = {
            ...newRounds[index],
            [field]: field === 'year' ? parseInt(value) : value
        };
        setRounds(newRounds);
    };

    const handleYearChange = (index, direction) => {
        const step = 1;
        const newYear = direction === 'left' 
            ? Math.max(-3000, rounds[index].year - step)
            : Math.min(2025, rounds[index].year + step);
        
        handleRoundChange(index, 'year', newYear);
    };

    const handleDragStart = (e, index) => {
        if (e.target !== e.currentTarget) {
            return;
        }
        setIsDragging(true);
        handleDrag(e, index);
    };

    const handleDrag = (e, index) => {
        if (!isDragging) return;
        
        const timeline = e.currentTarget;
        const rect = timeline.getBoundingClientRect();
        const x = e.type.includes('touch') ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
        const percentage = Math.max(0, Math.min(1, x / rect.width));
        
        // Convert percentage to year (-3000 to 2025)
        const rawYear = -3000 + (percentage * 5025);
        const newYear = Math.round(rawYear);
        handleRoundChange(index, 'year', newYear);
    };

    useEffect(() => {
        const handleGlobalPointerUp = () => {
            setIsDragging(false);
        };
        document.addEventListener('pointerup', handleGlobalPointerUp);
        return () => {
            document.removeEventListener('pointerup', handleGlobalPointerUp);
        };
    }, []);

    const renderYearInput = (round, index) => (
        <div>
            <label htmlFor={`time-${index}`} className="block text-sm font-medium text-gray-700 mb-1">
                Year
            </label>
            <div className="relative w-full h-32">
                <div 
                    className="absolute w-[calc(100%-80px)] h-12 bg-gray-200 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 cursor-grab active:cursor-grabbing rounded"
                    onMouseDown={(e) => handleDragStart(e, index)}
                    onTouchStart={(e) => handleDragStart(e, index)}
                    onMouseMove={(e) => handleDrag(e, index)}
                    onTouchMove={(e) => handleDrag(e, index)}
                    style={{ touchAction: 'none' }}
                >
                    {/* Left arrow button */}
                    <button
                        className="absolute -left-10 top-1/2 transform -translate-y-1/2 w-8 h-8 bg-transparent text-gray-700 rounded-lg transition-all duration-300 border border-gray-300 hover:border-blue-500 hover:bg-blue-50 flex items-center justify-center"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleYearChange(index, 'left');
                        }}
                    >
                        ←
                    </button>

                    {/* Right arrow button */}
                    <button
                        className="absolute -right-10 top-1/2 transform -translate-y-1/2 w-8 h-8 bg-transparent text-gray-700 rounded-lg transition-all duration-300 border border-gray-300 hover:border-blue-500 hover:bg-blue-50 flex items-center justify-center"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleYearChange(index, 'right');
                        }}
                    >
                        →
                    </button>

                    {/* Year indicator */}
                    <div 
                        className="absolute w-1 h-16 bg-blue-500 top-1/2 transform -translate-y-1/2"
                        style={{ 
                            left: `${((round.year + 3000) / 5025) * 100}%`
                        }}
                    >
                        <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                            <span className="text-white text-sm font-medium px-2 py-1 rounded bg-blue-500">
                                {formatYear(round.year)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const roundIds = Array(5).fill(null).map(() => uuidv4());
            const firstRoundId = roundIds[0];

            for (let i = 0; i < 5; i++) {
                const currentRoundId = roundIds[i];
                const nextRoundId = i < 4 ? roundIds[i + 1] : null;

                // First set the date to UTC time that corresponds to 9am EST
                const selectedDate = new Date(dailyChallengeDate);
                // Set to 14:00 UTC (which is 9:00 EST)
                selectedDate.setUTCHours(14, 0, 0, 0);
                const isoDateTime = selectedDate
                    .toISOString()
                    .replace('T', ' ')
                    .split('.')[0];

                console.log('isoDateTime', isoDateTime)

                const payload = {
                    event_name: rounds[i].eventName,
                    location_name: rounds[i].locationName,
                    year: rounds[i].year,
                    round_id: currentRoundId,
                    round_number: i + 1,
                    first_round: firstRoundId,
                    next_round: nextRoundId,
                    utc_offset: -5,
                    ...(i === 0 && { daily_challenge_date: isoDateTime })
                };

                const response = await fetch(
                    // `https://timetravel-dd209e3dce1f.herokuapp.com/generate-location-images`,
                    `http://127.0.0.1:2999/generate-location-images`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    }
                );

                const data = await response.json();

                if (data.status !== 'success') {
                    throw new Error(`Failed to create round ${i + 1}`);
                }

                if (i === 0) {
                    navigate(`/timeportal/${currentRoundId}`);
                }
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error creating time portal. Please try again.');
            setIsSubmitting(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 py-8">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-3xl font-bold mb-8 text-center">Create Time Portal Game</h1>
                
                <form onSubmit={handleSubmit} className="space-y-8">
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-xl font-semibold mb-4">Daily Challenge Date</h2>
                        <input
                            type="date"
                            value={dailyChallengeDate}
                            onChange={(e) => setDailyChallengeDate(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    {rounds.map((round, index) => (
                        <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                            <h2 className="text-xl font-semibold mb-4">Round {index + 1}</h2>
                            
                            <div className="space-y-4">
                                <div>
                                    <label htmlFor={`event-${index}`} className="block text-sm font-medium text-gray-700 mb-1">
                                        Event Name
                                    </label>
                                    <input
                                        type="text"
                                        id={`event-${index}`}
                                        value={round.eventName}
                                        onChange={(e) => handleRoundChange(index, 'eventName', e.target.value)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="e.g., The Fall of Rome"
                                        required
                                    />
                                </div>

                                <div>
                                    <label htmlFor={`location-${index}`} className="block text-sm font-medium text-gray-700 mb-1">
                                        Location
                                    </label>
                                    <input
                                        type="text"
                                        id={`location-${index}`}
                                        value={round.locationName}
                                        onChange={(e) => handleRoundChange(index, 'locationName', e.target.value)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="e.g., Rome, Italy"
                                        required
                                    />
                                </div>

                                {renderYearInput(round, index)}
                            </div>
                        </div>
                    ))}

                    <div className="flex justify-center">
                        <button
                            type="submit"
                            disabled={isSubmitting || rounds.some(round => !round.eventName || !round.locationName)}
                            className={`px-8 py-3 rounded-md text-white font-medium 
                                ${(isSubmitting || rounds.some(round => !round.eventName || !round.locationName))
                                    ? 'bg-gray-400 cursor-not-allowed' 
                                    : 'bg-blue-500 hover:bg-blue-600'
                                }`}
                        >
                            {isSubmitting ? 'Creating...' : 'Create Game'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TimePortalCreator; 