import React, { useState, useRef } from 'react';
import { LuVolume2, LuVolumeX } from "react-icons/lu";
import VideoModal from "./VideoModal";
import { logEvent, formatTimestamp } from "../utils";
const VideoCard = ({ video, isLastVideo, lastVideoRef }) => {
  const [showModal, setShowModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null); // null = no video loaded yet
  const videoRef = useRef();
  // console.log(video)
  const handleMouseEnter = () => {
    setIsHovered(true);

    // Set the video src to actually load it
    setVideoSrc(video.video_url);

    if (videoRef.current) {
      videoRef.current.muted = false;

      // Always handle the play Promise
      const playPromise = videoRef.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Playback actually started
          })
          .catch((error) => {
            // Autoplay was prevented or playback got interrupted
            console.warn("Video play interrupted:", error);
          });
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);

    if (videoRef.current) {
      // Safely pause first
      videoRef.current.muted = true;
      videoRef.current.pause();
      videoRef.current.currentTime = 0;

      // Optionally reset the video to free buffer
      // videoRef.current.load();

      // Now remove the src from state to show the thumbnail again
      setVideoSrc(null);
    }
  };
  // console.log(video)
  return (
    <>
    <div
      ref={isLastVideo ? lastVideoRef : null}
      className="bg-gray-900 rounded-lg overflow-hidden cursor-pointer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={async () => {
        setShowModal(true)
        // chatgpt, if this is an async function,
        // is it ok to use it in an onClick? 
        await logEvent({
          eventName: 'remix-open-public-video',
          user: 'anon_remix_user',
          video: `https://www.eggnog.ai/mix/${video?.id ?? 'unknown'}`,
          template: `http://www.eggnog.ai/remix/${video?.original_scene_id ?? 'unknown'}`,
          templateTitle: video?.original_scene_title ?? 'Untitled',
        });

      }}
    >
      {videoSrc ? (
        <video
          ref={videoRef}
          src={videoSrc}
          loop
          playsInline
          muted
          className="w-full h-48 object-cover"
        />
      ) : (
        <img
          src={video.thumbnail_url}
          alt="Video thumbnail"
          className="w-full h-48 object-cover"
        />
      )}

        <div className="p-4">
          <div className="flex justify-between items-start">
            {/* Left Aligned Content */}
            <div>
              <p className="text-gray-400 text-left text-xs">
                by {video.creator_name || "Anonymous"}
              </p>
              {/* First Line: View Count • Timestamp */}
              <p className="text-gray-400 text-left text-xs">
                {video.view_count >= 1000
                  ? `${(video.view_count / 1000).toFixed(1)}k`
                  : video.view_count || 0}{" "}
                views
              </p>
              <p className="text-gray-400 text-left text-xs">
                {formatTimestamp(video.created_at)}
              </p>
              {/* Second Line: Creator Name */}
            </div>
            
            {/* Right Aligned Remix Link */}
            <p className="font-bold text-green-400 self-end text-xs text-right">Remix →</p>
          </div>
        </div>

      </div>

      {showModal && (
        <VideoModal
          video={video}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default VideoCard;
