import config from './endpoint.config';


function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return `${text.substring(0, maxLength)}...`;
  }
}

function getEndpoint() {
  return config.API_ENDPOINT;
  // const endpoint = 'https://ai-apps-backend-80af17cb1aaa.herokuapp.com';
  // const endpoint = 'http://127.0.0.1:2999';
  // console.log("endpoint: ", endpoint)
  // return endpoint
}

const logEvent = async ({ user = '', eventName, episodeId = '', deviceId = '', ...additionalProps } = {}) => {
  try {
    const response = await fetch(`${getEndpoint()}/log`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user,
        event_name: eventName,
        episodeId,
        device_id: deviceId,
        timestamp: new Date().toISOString(),
        ...additionalProps
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to log event');
    }

    return await response.json();
  } catch (error) {
    console.error('Error logging event:', error);
    // Optionally rethrow or handle error
  }
};

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const now = Date.now();
  const diff = now - date.getTime(); // in milliseconds

  // Calculate time differences
  const minutes = Math.floor(diff / (1000 * 60));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (minutes < 1) {
    return "Just now";
  } else if (minutes < 60) {
    return `${minutes}m ago`;
  } else if (hours < 24) {
    return `${hours}h ago`;
  } else if (days < 7) {
    return `${days}d ago`;
  } else {
    // If it's more than a week old, show as short date, e.g., "Jan 12 2024"
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric"
    });
  }
}


const infiniteThumbnail = 'https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/header_thumbnails%2Feggnog_thumbnails.png?alt=media'
export {
	truncateText,
  infiniteThumbnail,
  getEndpoint,
  logEvent,
  formatTimestamp
}