import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInAnonymously } from 'firebase/auth';
import { auth } from '../Firebase.js';
import { useUser } from '../contexts/UserContext';
import { db } from '../Firebase.js';
import {
  collection,
  query,
  orderBy,
  getDocs,
  limit,
  where,
  startAfter,
  onSnapshot,
  doc
} from 'firebase/firestore';
import { useMediaQuery } from 'react-responsive';
import LazyVideo from './LazyVideo';
import InfiniteScroll from 'react-infinite-scroll-component';
import PublicVideos from './PublicVideos';
import SearchBar from './SearchBar';


import { useParams } from 'react-router-dom';
import { getEndpoint } from '../utils';
import RelatedVideos from './RelatedVideos';
import SceneLibrary from './SceneLibrary';
import RemixEditor from './RemixEditor';
import { logEvent } from "../utils";

export function RemixHome() {
  const [activeTab, setActiveTab] = useState('recentlyCreated');
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  const { currentUser, loading: userLoading } = useUser();
  const username = currentUser?.email || currentUser?.uid;
  console.log(`userId: ${username}`);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      <header className="sticky top-0 bg-black/90 backdrop-blur-sm p-4 z-10">
          <h1 className="text-2xl font-bold text-center">
            <a 
              href="http://www.eggnog.ai/remix" 
              className="hover:text-gray-300 transition-colors inline-flex items-center gap-2"
            >
              🥚 remix
            </a>
          </h1>
          <h2 className="text-md text-center text-gray-400 mt-2">
          Remix popular movie scenes, TV shows, and current events ✨
        </h2>
      </header>

      {isDesktopOrLaptop ? (
        <div className="flex flex-1">
          <div className="w-64 flex flex-col items-start p-4">
            <p className="text-gray-400 text-left px-4 py-2 text-sm">
              Eggnog Remix
            </p>
            <button
              className={`w-full text-left py-3 px-4 rounded-lg mb-2 ${
                activeTab === 'recentlyCreated'
                  ? 'bg-green-600 text-white font-bold'
                  : 'text-gray-400 hover:bg-gray-700'
              }`}
              onClick={() => setActiveTab('recentlyCreated')}
            >
              Recently Created
            </button>
            <button
              className={`w-full text-left py-3 px-4 rounded-lg ${
                activeTab === 'sceneLibrary'
                  ? 'bg-green-600 text-white font-bold'
                  : 'text-gray-400 hover:bg-gray-700'
              }`}
              onClick={() => setActiveTab('sceneLibrary')}
            >
              Scene Library
            </button>
          </div>

          <div className="w-5/6 flex-1 flex justify-center p-4">
            <div className="w-3/4">
              {activeTab === 'recentlyCreated' && <PublicVideos />}
              {activeTab === 'sceneLibrary' && <SceneLibrary />}
            </div>
          </div>

          <div className="w-64 flex flex-col items-start p-4">
            {/* Placeholder for maintaining layout */}
          </div>
        </div>
      ) : (
        <div className="flex flex-col flex-1">
          <div className="flex justify-center font-bold">
            <button
              className={`flex-1 py-3 text-center ${
                activeTab === 'recentlyCreated'
                  ? 'text-green-400 border-b-2 border-green-400 font-bold'
                  : 'text-gray-400 hover:text-white'
              }`}
              onClick={() => setActiveTab('recentlyCreated')}
            >
              Recently Created
            </button>
            <button
              className={`flex-1 py-3 text-center ${
                activeTab === 'sceneLibrary'
                  ? 'text-green-400 border-b-2 border-green-400 font-bold'
                  : 'text-gray-400 hover:text-white'
              }`}
              onClick={() => setActiveTab('sceneLibrary')}
            >
              Scene Library
            </button>
          </div>
          <div className="flex-1 p-4">
            {activeTab === 'recentlyCreated' && <PublicVideos />}
            {activeTab === 'sceneLibrary' && <SceneLibrary />}
          </div>
        </div>
      )}

      <footer className="mt-auto m-2">
        <div className="mx-auto px-4 py-4 flex justify-center text-gray-400">
          <a
            href="https://twitter.com/eggnog_ai"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-gray-300 transition-colors flex items-center gap-2 mx-2"
          >
            <span>Twitter</span>
          </a>
          <a
            href="https://twitter.com/eggnog_ai"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-gray-300 transition-colors flex items-center gap-2 mx-2"
          >
            <span>Discord</span>
          </a>
        </div>
      </footer>
    </div>
  );
}
// Main Component with Updated Routing
const SceneRemix = () => {
  const { id } = useParams();
  
  return id ? <RemixEditor /> : <RemixHome />;
};


export default SceneRemix;