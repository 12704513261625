import React, { useState, useEffect } from 'react';
import { getEndpoint } from '../utils';
import VideoCard from './VideoCard.jsx'
import { logEvent } from "../utils";
import { fetchRelatedVideos } from "../Firebase.js";



const RelatedVideos = ({ originalSceneId, videoId, originalSceneTitle, showMakeOne=true }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideos = async () => {
      if (!originalSceneId) return;

      try {
        setLoading(true);
        const fetchedVideos = await fetchRelatedVideos(originalSceneId);
        setVideos(fetchedVideos);
      } catch (error) {
        console.error('Error fetching related videos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, [originalSceneId]);

  return (
    <div className="mt-12 border-t border-gray-800 pt-8">
      <div className="">
        <h2 className="text-2xl font-bold mb-6">More remixes like this</h2>
      </div>
      
      {loading ? (
        <div className="flex justify-center items-center p-4">
          <div className="w-10 h-10 rounded-full border-4 border-gray-700 border-t-green-400 animate-[spin_0.8s_ease-in-out_infinite]"></div>
        </div>
      ) : videos.length > 1 ? (
        <div className="relative">
          <div className="overflow-x-auto pb-4 no-scrollbar">
            <div className="flex gap-6 w-max">
              {videos.map((video) => (
                (video.id != videoId) && 
                <div className="w-60" key={video.id}>
                  <VideoCard
                    video={video}
                    isLastVideo={false}
                  />
                </div>
              ))}
              {/* CTA Button at the end */}

              {showMakeOne && (
                <div className="w-60">
                  <button
                    onClick={async () => {
                      await logEvent({
                        eventName: 'remix-create-from-related-videos',
                        user: 'anon_remix_user',
                        video: `https://www.eggnog.ai/remix/${originalSceneId}`,
                        template: `http://www.eggnog.ai/remix/${originalSceneId}`,
                        templateTitle: originalSceneTitle,
                      });
                      window.location.href = `https://www.eggnog.ai/remix/${originalSceneId}`;
                    }}
                    // Keep all the same classes to preserve layout and styling
                    className="block h-full min-h-52 border border-green-400 bg-black rounded-lg hover:bg-gray-800 transition-colors"
                  >
                    <div className="h-full flex flex-col items-center justify-center px-6 text-center gap-2">
                      <span className="text-green-400 text-lg font-medium">
                        Remix this scene
                      </span>
                      <span className="text-green-400/60 text-sm">
                        it takes less than 30s!!
                      </span>
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>
          <style jsx>{`
            .no-scrollbar {
              -ms-overflow-style: none;
              scrollbar-width: none;
            }
            .no-scrollbar::-webkit-scrollbar {
              display: none;
            }
          `}</style>
        </div>
      ) : (
        showMakeOne ? (
          <div className="text-center p-4">
           <p className="text-gray-400">No related videos found</p>
           <a 
             href={`http://www.eggnog.ai/remix/${originalSceneId}`}
             className="text-gray-400 font-bold inline-flex items-center gap-1 mt-2 hover:text-green-300 transition-colors"
           >
             Make one
           </a>
          </div>
        ) : 
        (
          <div className="text-center p-4">
           <p className="text-gray-400">No related videos found. Make the first one!</p>
          </div>
        )
      )}
    </div>
  );  
};

export default RelatedVideos;