import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { auth } from '../Firebase.js';

const UserContext = createContext();
export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [idToken, setIdToken] = useState(null);
  const [authInitialized, setAuthInitialized] = useState(false);

  // Primary auth state listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      // If there's a user (Google or anonymous), set them
      if (user) {
        setCurrentUser(user);
        try {
          const token = await user.getIdToken(true);
          setIdToken(token);
        } catch (error) {
          console.error('Error fetching ID token:', error);
        }
      } else {
        // If no user is present and we haven't tried anonymous auth yet
        if (!authInitialized) {
          try {
            await signInAnonymously(auth);
            // Don't setCurrentUser here - the auth state listener will handle it
          } catch (error) {
            console.error('Error during anonymous sign-in:', error);
            setCurrentUser(null);
            setIdToken(null);
          }
          setAuthInitialized(true);
        } else {
          // If we've already tried auth and still no user, clear everything
          setCurrentUser(null);
          setIdToken(null);
        }
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [authInitialized]);

  // Token refresh listener
  useEffect(() => {
    if (currentUser) {
      const unsubscribe = auth.onIdTokenChanged(async (user) => {
        if (user) {
          try {
            const token = await user.getIdToken();
            setIdToken(token);
          } catch (error) {
            console.error('Error refreshing ID token:', error);
          }
        }
      });
      return () => unsubscribe();
    }
  }, [currentUser]);

  return (
    <UserContext.Provider value={{ 
      currentUser, 
      loading, 
      idToken,
      // You might want to expose whether the user is anonymous
      isAnonymous: currentUser?.isAnonymous ?? false 
    }}>
      {children}
    </UserContext.Provider>
  );
};