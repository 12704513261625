// LazyVideo.jsx
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { LuRepeat2 } from "react-icons/lu";

const LazyVideo = ({ thumbnailUrl, videoUrl, title, isNew, onClick, speakerIds, usageCount }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Load only once when in view
    threshold: 0.25,    // Trigger when 25% of the component is visible
  });

  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <div
      ref={ref}
      onClick={onClick}
      className="bg-gray-900 rounded-lg overflow-hidden cursor-pointer transform hover:scale-105 transition"
    >
      <div
        className="relative w-full h-48 overflow-hidden"
        onMouseEnter={() => setIsPlaying(true)}
        onMouseLeave={() => setIsPlaying(false)}
      >
        {inView && isPlaying ? (
          <video
            src={videoUrl}
            muted
            loop
            autoPlay
            className="absolute w-full h-full object-cover"
          />
        ) : (
          <img
            src={thumbnailUrl}
            alt={title || 'Scene Thumbnail'}
            className="absolute w-full h-full object-cover"
          />
        )}
        {/* "New" Badge */}
        {isNew && (
          <div className="absolute top-2 right-2 bg-purple-500 text-white text-xs font-bold px-2 py-1 rounded">
            New
          </div>
        )}
      </div>
      <div className="p-4 space-y-2">
        <p className="text-gray-400 font-bold text-sm">{title}</p>
        <p className="text-gray-600">
          {Object.keys(speakerIds || {})
            .map(name =>
              name
                .replace(/_/g, ' ')
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ')
            )
            .join(', ')}
        </p>
        <div className="grid grid-cols-2">
          <div className="flex items-center gap-1">
            <LuRepeat2 className="w-4 h-4 text-gray-400" />
            <p className="font-bold text-gray-400 text-left text-xs">
              {usageCount >= 1000
                ? `${(usageCount / 1000).toFixed(1)}k`
                : usageCount}
            </p>
          </div>
        <div className='text-right'>
        <p className="font-bold text-green-400 self-end text-sm">Remix →</p>
        </div>
        </div>
      </div>

    </div>
  );
};

export default LazyVideo;
