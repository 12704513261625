import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, auth } from '../Firebase.js';
import {
  doc,
  updateDoc,
  getDoc, 
  onSnapshot,
  runTransaction
} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { ChevronDown, ChevronUp } from 'lucide-react';
import LivePortraitModal from './LivePortraitModal';

const LoadingSpinner = () => (
    <div className="flex justify-center items-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
    </div>
);


const Modal = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    // Define the function to handle `Esc` key press
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleEsc);

    // Remove event listener when the component unmounts
    return () => document.removeEventListener("keydown", handleEsc);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex justify-center items-center p-4">
      <div className="relative max-w-4xl max-h-[90vh] w-full h-full">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-white bg-black bg-opacity-50 rounded-full w-8 h-8 flex items-center justify-center z-10 hover:bg-opacity-75 transition-colors"
          aria-label="Close modal"
        >
          &times;
        </button>
        <div className="w-full h-full flex items-center justify-center">
          {children}
        </div>
      </div>
    </div>
  );
};



const TextEpisodeViewer = () => {
    const { episodeId } = useParams();
    const navigate = useNavigate();
    const [episode, setEpisode] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [maxSpeedFactors, setMaxSpeedFactors] = useState({});
    const [activeEdits, setActiveEdits] = useState({});
    const isEditingRef = useRef(false);
    const [expandedShots, setExpandedShots] = useState({});

    const [liveportraitModalVisible, setLiveportraitModalVisible] = useState(false)
    const [liveportraitInfo, setLivePortraitInfo] = useState(null)

      const createPrompts = async (shotIndex, episodeData) => {
        try {
            const shot = episodeData.processed_shots[shotIndex];
            const response = await axios.post('https://ai-apps-backend-80af17cb1aaa.herokuapp.com/generate_first_frame_prompt', {
            // const response = await axios.post('http://127.0.0.1:2999/generate_first_frame_prompt', {
                episodeId: episodeId,
                shotIndex: shotIndex,
                shot_type: shot.type,
                shot_character_1: shot.character_1,
                shot_character_2: shot.character_2,
                shot_action_description: shot.action_description,
                shot_set_description: shot.set_description,
                background_facts: episodeData.background_facts,
                full_episode_script: episodeData.processed_shots.map(shot => 
                    `${shot.type}: ${shot.character_1 ? shot.character_1 + (shot.character_2 ? ` & ${shot.character_2}` : '') + ' - ' : ''}${shot.dialogue || shot.action_description}`
                ).join('\n'),
                characters: episodeData.characters
            });
            console.log('Prompt generation initiated:', response.data);
        } catch (error) {
            console.error('Error creating prompts:', error);
        }
    };

    const handleNarratorVoiceChange = (value) => {
        const updatedEpisode = { ...episode, narrator_voice: value };
        setEpisode(updatedEpisode);
        debouncedSaveChanges(updatedEpisode);
    };

    useEffect(() => {
      setLoading(true);
      const episodeRef = doc(db, 'text-episodes', episodeId);
      
      const unsubscribe = onSnapshot(episodeRef, 
          async (doc) => {
              if (doc.exists()) {
                  const episodeData = { id: doc.id, ...doc.data() };
                  
                  if (isEditingRef.current) {
                      const mergedProcessedShots = episodeData.processed_shots.map((shot, index) => {
                          const activeEdit = activeEdits[index];
                          if (activeEdit) {
                              return {
                                  ...shot,
                                  [activeEdit.field]: activeEdit.value
                              };
                          }
                          return shot;
                      });
                      episodeData.processed_shots = mergedProcessedShots;
                  }
                  
                  // Initialize expandedShots for new shots
                  setExpandedShots(prev => {
                      const newExpanded = { ...prev };
                      episodeData.processed_shots.forEach((_, index) => {
                          if (!(index in newExpanded)) {
                              newExpanded[index] = false; // All shots collapsed by default
                          }
                      });
                      return newExpanded;
                  });
                  
                  setEpisode(episodeData);
                  setLoading(false);
              } else {
                  setError("Episode not found");
                  setLoading(false);
              }
          },
          (err) => {
              console.error("Error fetching episode:", err);
              setError("Failed to fetch episode. Please try again.");
              setLoading(false);
          }
      );
  
      return () => unsubscribe();
  }, [episodeId]);

    const toggleShot = (shotIndex) => {
        setExpandedShots(prev => ({
            ...prev,
            [shotIndex]: !prev[shotIndex]
        }));
    };

    const generateAllPrompts = async () => {
        if (!episode || !episode.processed_shots) return;
        
        try {
            const needsPromptShots = episode.processed_shots
                .map((shot, index) => ({ shot, index }))
                .filter(({ shot }) => 
                    !shot.first_frame_prompt && 
                    shot.first_frame_prompt !== "PROCESSING"
                );
            
            if (needsPromptShots.length > 0) {
                console.log(`Generating prompts for ${needsPromptShots.length} shots...`);
                for (const { shot, index } of needsPromptShots) {
                    await createPrompts(index, episode);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
            } else {
                console.log("No shots need prompts generated.");
            }
        } catch (error) {
            console.error("Error generating prompts:", error);
        }
    };

    const generateAllStartingShots = async () => {
        if (!episode || !episode.processed_shots) return;
        
        try {
            const allShots = episode.processed_shots
                .map((shot, index) => ({ shot, index }));
            
            if (allShots.length > 0) {
                console.log(`Creating starting shots for ${allShots.length} shots...`);
                for (const { shot, index } of allShots) {
                    await createStartingShot(index, shot.first_frame_prompt || '');
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
            } else {
                console.log("No shots available to generate.");
            }
        } catch (error) {
            console.error("Error generating starting shots:", error);
        }
    };

    const handleNavigation = (targetEpisodeId) => {
        if (targetEpisodeId) {
            navigate(`/text-episode-viewer/${targetEpisodeId}`);
        }
    };

    const saveChanges = useCallback(async (updatedEpisode) => {
        try {
            setSaving(true);
            const episodeRef = doc(db, 'text-episodes', episodeId);
            await updateDoc(episodeRef, {
                processed_shots: updatedEpisode.processed_shots,
                narrator_voice: updatedEpisode.narrator_voice || null // Add this line to persist narrator_voice
            });
            console.log("Changes saved successfully");
        } catch (err) {
            console.error("Error saving changes:", err);
            alert("Failed to save changes. Please try again.");
        } finally {
            setSaving(false);
        }
    }, [episodeId]);

    const debouncedSaveChanges = useCallback(
        debounce((updatedEpisode) => saveChanges(updatedEpisode), 1000),
        [saveChanges]
    );

    const handleTextFieldFocus = (shotIndex, field, value) => {
        isEditingRef.current = true;
        setActiveEdits(prev => ({
            ...prev,
            [shotIndex]: { field, value }
        }));
    };

    const handleTextFieldBlur = (shotIndex) => {
        isEditingRef.current = false;
        setActiveEdits(prev => {
            const newEdits = { ...prev };
            delete newEdits[shotIndex];
            return newEdits;
        });
    };

    const handleShotChange = (shotIndex, field, value) => {
        setActiveEdits(prev => ({
            ...prev,
            [shotIndex]: { field, value }
        }));
    
        const updatedEpisode = { ...episode };
        const updatedShot = { ...updatedEpisode.processed_shots[shotIndex] };
    
        if (field === 'type') {
            updatedShot.type = value;
            if (value === 'Dialogue' && !updatedShot.character_1) {
                updatedShot.character_1 = Object.keys(episode.characters)[0] || '';
            }
        } else if (field === 'character_1') {
            updatedShot.character_1 = value;
            // If Character 1 is set to None, Character 2 should also be None
            if (!value) {
                updatedShot.character_2 = '';
            }
        } else if (field === 'character_2') {
            // Only allow Character 2 to be set if Character 1 is set
            if (updatedShot.character_1) {
                updatedShot.character_2 = value;
            }
        } else {
            updatedShot[field] = value;
        }
    
        updatedEpisode.processed_shots[shotIndex] = updatedShot;
        setEpisode(updatedEpisode);
        debouncedSaveChanges(updatedEpisode);
    };

    const renderPromptTextarea = (shot, shotIndex, field, label) => (
        <div className="mt-4 p-3 bg-yellow-100 rounded">
            <h6 className="font-semibold text-sm mb-2">{label}:</h6>
            <textarea
                value={shot[field]}
                onChange={(e) => handleShotChange(shotIndex, field, e.target.value)}
                onFocus={() => handleTextFieldFocus(shotIndex, field, shot[field])}
                onBlur={() => handleTextFieldBlur(shotIndex)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                rows="5"
            />
        </div>
    );

    const handleCreatePrompts = async (shotIndex) => {
        if (!episode) return;
        await createPrompts(shotIndex, episode);
    };

    const createStartingShot = async (shotIndex, currentFirstFramePrompt) => {
        const newImageKey = Date.now().toString();
        try {
          const response = await axios.post('https://ai-apps-backend-80af17cb1aaa.herokuapp.com/enqueue-image-generation', {
            episodeId: episode.id,
            shotIndex: shotIndex,
            shot_type: episode.processed_shots[shotIndex].type,
            shot_character_1: episode.processed_shots[shotIndex].character_1,
            shot_character_2: episode.processed_shots[shotIndex].character_2,
            characters: episode.characters,
            first_frame_prompt: currentFirstFramePrompt,
            processed_shot: episode.processed_shots[shotIndex],
            imageKey: newImageKey
          });
          console.log('Image generation job enqueued:', response.data);
        } catch (error) {
          console.error('Error creating starting shot:', error);
          alert('Failed to create starting shot. Please try again.');
        }
    };
  
      
      const animateWithVideoPrompt = async (shotIndex, imageKey, imageUrl) => {
        const newVideoKey = Date.now().toString();
        try {
          const shot = episode.processed_shots[shotIndex];
          const response = await axios.post('https://ai-apps-backend-80af17cb1aaa.herokuapp.com/animate-with-video-prompt', {
            episodeId: episode.id,
            shotIndex: shotIndex,
            imageUrl: imageUrl,
            videoPrompt: shot.video_prompt,
            videoKey: newVideoKey
          });
          console.log('Animation job enqueued:', response.data);
        } catch (error) {
          console.error('Error initiating animation:', error);
          alert('Failed to start animation. Please try again.');
        }
    };
    
    const showLiveportraitModal = (shotIndex, imageKey, imageUrl) => {
        setLivePortraitInfo({shotIndex:shotIndex, imageKey:imageKey, imageUrl:imageUrl})
        setLiveportraitModalVisible(true)
    }
    
    const animateWithLiveportrait = async (shotIndex, imageKey, imageUrl, videoUrl) => {
        const newVideoKey = Date.now().toString();
        try {
          const response = await axios.post('https://ai-apps-backend-80af17cb1aaa.herokuapp.com/animate-with-live-portrait', {
            episodeId: episode.id,
            shotIndex: shotIndex,
            imageUrl: imageUrl,
            videoKey: newVideoKey,
            livePortraitUrl: videoUrl,
          });
          console.log('Liveportrait job enqueued:', response.data);
        } catch (error) {
          console.error('Error initiating animation:', error);
          alert('Failed to start animation. Please try again.');
        }
    };
    
    const addDialogueToVideo = async (shotIndex, videoKey, videoUrl) => {
        const newDialogueKey = Date.now().toString();
        try {
          const shot = episode.processed_shots[shotIndex];
    
          const requestData = {
            episodeId: episode.id,
            shotIndex: shotIndex,
            videoUrl: videoUrl,
            dialogue: shot.dialogue,
            shotData: {
              type: shot.type,
              character_1: shot.character_1,
              character_2: shot.character_2,
              action_description: shot.action_description,
              set_description: shot.set_description,
              first_frame_prompt: shot.first_frame_prompt,
              video_prompt: shot.video_prompt,
            },
            dialogueKey: newDialogueKey,
            characters: episode.characters,
            maxSpeedFactor: maxSpeedFactors[shotIndex] || 1.0
          };
    
          if (episode.narrator_voice) {
            requestData.narrator_voice = episode.narrator_voice;
          }
    
          const response = await axios.post('https://ai-apps-backend-80af17cb1aaa.herokuapp.com/add-dialogue', requestData);
          console.log('requestData', requestData);
          console.log('Dialogue addition job enqueued:', response.data);
        } catch (error) {
          console.error('Error initiating dialogue addition:', error);
          alert('Failed to start dialogue addition. Please try again.');
        }
    };
  
      const handleMaxSpeedFactorChange = (shotIndex, value) => {
        setMaxSpeedFactors(prev => ({
          ...prev,
          [shotIndex]: parseFloat(value)
        }));
      };
  
      const openModal = (content) => {
        setModalContent(content);
      };
  
      const closeModal = () => {
        setModalContent(null);
      };
  
      const renderMedia = (url, type) => {
        if (type === 'image') {
          return (
            <img 
              src={url} 
              alt="Enlarged view" 
              className="max-w-full max-h-[90vh] object-contain rounded-lg shadow-lg" 
            />
          );
        } else if (type === 'video') {
          return (
            <video 
              src={url} 
              controls
              autoPlay
              className="max-w-full max-h-[90vh] rounded-lg shadow-lg"
            >
              Your browser does not support the video tag.
            </video>
          );
        }
      };

      const renderMediaWithErrorHandling = (mediaData, mediaType, 
        onActionClick, actionLabel,
        secondaryActionClick, secondaryActionLabel) => {
        if (mediaData.status === 'ERROR') {
          return (
            <div className="flex flex-col h-full">
              <div className="relative w-full pb-[177.78%]">
                <div className="absolute inset-0 flex items-center justify-center bg-red-50 border border-red-200 rounded-lg">
                  <div className="text-red-600 text-sm text-center px-2">
                    Generation failed
                  </div>
                </div>
              </div>
            </div>
          );
        }
    
        return (
          <div className="flex flex-col">
            <div 
              className="relative w-full pb-[177.78%] cursor-pointer" 
              onClick={() => mediaData.status === 'COMPLETED' && openModal(renderMedia(mediaData.url, mediaType))}
            >
              {mediaData.status === 'PROCESSING' ? (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-200 rounded-lg">
                  <LoadingSpinner />
                </div>
              ) : (
                mediaType === 'image' ? (
                  <img 
                    src={mediaData.url} 
                    alt={`Generated ${mediaType}`} 
                    className="absolute inset-0 w-full h-full object-cover rounded-lg shadow-md"
                  />
                ) : (
                  <video 
                    src={mediaData.url} 
                    className="absolute inset-0 w-full h-full object-cover rounded-lg shadow-md"
                  >
                    Your browser does not support the video tag.
                  </video>
                )
              )}
            </div>
            {mediaData.status === 'COMPLETED' && actionLabel && (
              <button
                onClick={onActionClick}
                className="mt-1 w-full px-2 py-1 text-xs bg-indigo-500 text-white rounded hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 transition-colors duration-300"
              >
                {actionLabel}
              </button>
            )}
            {mediaData.status === 'COMPLETED' && secondaryActionLabel && (
              <button
                onClick={secondaryActionClick}
                className="mt-1 w-full px-2 py-1 text-xs bg-indigo-500 text-white rounded hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 transition-colors duration-300"
              >
                {secondaryActionLabel}
              </button>
            )}
          </div>
        );
      };
  
      const renderEpisodeScript = () => {
        return (
            <div className="bg-gray-100 p-4 rounded-lg mb-4">
                <h4 className="font-bold text-lg mb-2">Episode {episode.episode_number}</h4>
                {episode.processed_shots.map((shot, shotIndex) => (
                    <div key={shotIndex} className="mb-4 bg-white p-3 rounded shadow relative">
                        <div 
                            className="flex justify-between items-center cursor-pointer p-2 hover:bg-gray-50"
                            onClick={() => toggleShot(shotIndex)}
                        >
                            <h5 className="font-semibold text-blue-600">
                                Shot {shotIndex + 1}: {shot.type} 
                                {shot.character && ` - ${shot.character}`}
                                {shot.dialogue && ` - "${shot.dialogue.substring(0, 50)}${shot.dialogue.length > 50 ? '...' : ''}"`}
                                {!shot.dialogue && shot.action_description && ` - ${shot.action_description.substring(0, 50)}${shot.action_description.length > 50 ? '...' : ''}`}
                            </h5>
                            {expandedShots[shotIndex] ? (
                                <ChevronUp className="w-5 h-5 text-gray-500" />
                            ) : (
                                <ChevronDown className="w-5 h-5 text-gray-500" />
                            )}
                        </div>

                        {expandedShots[shotIndex] && (
                            <div className="mt-4">
                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Type:</label>
                                    <select
                                        value={shot.type}
                                        onChange={(e) => {
                                            const newType = e.target.value;
                                            handleShotChange(shotIndex, 'type', newType);
                                            if (newType === 'Dialogue' && !shot.character) {
                                                handleShotChange(shotIndex, 'character', Object.keys(episode.characters)[0] || '');
                                            }
                                        }}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    >
                                        <option value="Action">Action</option>
                                        <option value="Dialogue">Dialogue</option>
                                    </select>
                                </div>
    
                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Character 1:</label>
                                    <select
                                        value={shot.character_1 || ''}
                                        onChange={(e) => handleShotChange(shotIndex, 'character_1', e.target.value)}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    >
                                        <option value="">None</option>
                                        {Object.entries(episode.characters).map(([name, details]) => (
                                            <option key={name} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Character 2:</label>
                                    <select
                                        value={shot.character_2 || ''}
                                        onChange={(e) => handleShotChange(shotIndex, 'character_2', e.target.value)}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        disabled={!shot.character_1} // Disable if Character 1 is None
                                    >
                                        <option value="">None</option>
                                        {Object.entries(episode.characters)
                                            .filter(([name]) => name !== shot.character_1) // Filter out Character 1
                                            .map(([name, details]) => (
                                                <option key={name} value={name}>
                                                    {name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
    
                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Dialogue:</label>
                                    <textarea
                                        value={shot.dialogue || ''}
                                        onChange={(e) => handleShotChange(shotIndex, 'dialogue', e.target.value)}
                                        onFocus={() => handleTextFieldFocus(shotIndex, 'dialogue', shot.dialogue || '')}
                                        onBlur={() => handleTextFieldBlur(shotIndex)}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        rows="3"
                                    />
                                </div>

                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Action:</label>
                                    <textarea
                                        value={shot.action_description || ''}
                                        onChange={(e) => handleShotChange(shotIndex, 'action_description', e.target.value)}
                                        onFocus={() => handleTextFieldFocus(shotIndex, 'action_description', shot.action_description || '')}
                                        onBlur={() => handleTextFieldBlur(shotIndex)}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        rows="3"
                                    />
                                </div>

                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Set:</label>
                                    <textarea
                                        value={shot.set_description || ''}
                                        onChange={(e) => handleShotChange(shotIndex, 'set_description', e.target.value)}
                                        onFocus={() => handleTextFieldFocus(shotIndex, 'set_description', shot.set_description || '')}
                                        onBlur={() => handleTextFieldBlur(shotIndex)}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        rows="3"
                                    />
                                </div>
    
                                <button
                                    onClick={() => handleCreatePrompts(shotIndex)}
                                    className="mt-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                                >
                                    Create Prompt
                                </button>

    
                                {shot.first_frame_prompt === "PROCESSING" ? (
                                    <div className="mt-4 p-3 bg-yellow-100 rounded">
                                        <h6 className="font-semibold text-sm mb-2">Generating First Frame Prompt:</h6>
                                        <LoadingSpinner />
                                    </div>
                                ) : shot.first_frame_prompt ? (
                                    <div className="mt-4 p-3 bg-yellow-100 rounded">
                                        <h6 className="font-semibold text-sm mb-2">First Frame Prompt:</h6>
                                        <textarea
                                            value={shot.first_frame_prompt}
                                            onChange={(e) => handleShotChange(shotIndex, 'first_frame_prompt', e.target.value)}
                                            onFocus={() => handleTextFieldFocus(shotIndex, 'first_frame_prompt', shot.first_frame_prompt)}
                                            onBlur={() => handleTextFieldBlur(shotIndex)}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                            rows="5"
                                        />
                                    </div>
                                ) : null}

                                {/* Video Prompt section */}
                                {shot.video_prompt === "PROCESSING" ? (
                                    <div className="mt-4 p-3 bg-blue-100 rounded">
                                        <h6 className="font-semibold text-sm mb-2">Generating Video Prompt:</h6>
                                        <LoadingSpinner />
                                    </div>
                                ) : shot.video_prompt ? (
                                    <div className="mt-4 p-3 bg-blue-100 rounded">
                                        <h6 className="font-semibold text-sm mb-2">Video Prompt:</h6>
                                        <textarea
                                            value={shot.video_prompt}
                                            onChange={(e) => handleShotChange(shotIndex, 'video_prompt', e.target.value)}
                                            onFocus={() => handleTextFieldFocus(shotIndex, 'video_prompt', shot.video_prompt)}
                                            onBlur={() => handleTextFieldBlur(shotIndex)}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                            rows="5"
                                        />
                                    </div>
                                ) : null}

                                {/* Create Starting Shot button */}
                                {shot.first_frame_prompt && shot.first_frame_prompt !== "PROCESSING" && (
                                    <button
                                        onClick={() => createStartingShot(shotIndex, shot.first_frame_prompt)}
                                        className="mt-2 px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
                                    >
                                        Create Starting Shot
                                    </button>
                                )}

                                {/* Generated Images section */}
                                {shot.image_urls && Object.keys(shot.image_urls).length > 0 && (
                                    <div className="mt-4">
                                        <h6 className="font-semibold text-sm mb-2">Generated Images:</h6>
                                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
                                            {Object.entries(shot.image_urls)
                                                .sort(([, a], [, b]) => new Date(b.timestamp) - new Date(a.timestamp))
                                                .map(([key, imageData]) => (
                                                    <div key={key}>
                                                        {renderMediaWithErrorHandling(
                                                            imageData,
                                                            'image',
                                                            () => animateWithVideoPrompt(shotIndex, key, imageData.url),
                                                            shot.video_prompt ? 'Animate' : null,
                                                            () => showLiveportraitModal(shotIndex, key, imageData.url),
                                                            'Add live portrait expression',
                                                        )}
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )}

                                {/* Generated Videos section */}
                                {shot.video_urls && Object.keys(shot.video_urls).length > 0 && (
                                    <div className="mt-4">
                                        <h6 className="font-semibold text-sm mb-2">Generated Videos:</h6>
                                        
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Max Speed up: {maxSpeedFactors[shotIndex] || 1.5}x
                                            </label>
                                            <input
                                                type="range"
                                                min="0.25"
                                                max="5.0"
                                                step="0.25"
                                                value={maxSpeedFactors[shotIndex] || 1.5}
                                                onChange={(e) => handleMaxSpeedFactorChange(shotIndex, e.target.value)}
                                                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                            />
                                        </div>

                                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
                                            {Object.entries(shot.video_urls)
                                                .sort(([, a], [, b]) => new Date(b.timestamp) - new Date(a.timestamp))
                                                .map(([key, videoData]) => (
                                                    <div key={key}>
                                                        {renderMediaWithErrorHandling(
                                                            videoData,
                                                            'video',
                                                            () => addDialogueToVideo(shotIndex, key, videoData.url),
                                                            'Add dialogue'
                                                        )}
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )}

                                {/* Videos with Dialogue section */}
                                {shot.video_dialogue_urls && Object.keys(shot.video_dialogue_urls).length > 0 && (
                                    <div className="mt-4">
                                        <h6 className="font-semibold text-sm mb-2">Videos with Dialogue:</h6>
                                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
                                            {Object.entries(shot.video_dialogue_urls)
                                                .sort(([, a], [, b]) => new Date(b.timestamp) - new Date(a.timestamp))
                                                .map(([key, videoData]) => (
                                                    <div key={key}>
                                                        {renderMediaWithErrorHandling(
                                                            videoData,
                                                            'video'
                                                        )}
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ))}

                {/* Batch action buttons */}
                <div className="mt-4 flex gap-4">
                    <button
                        onClick={() => {
                            // Expand all shots
                            const allExpanded = {};
                            episode.processed_shots.forEach((_, index) => {
                                allExpanded[index] = true;
                            });
                            setExpandedShots(allExpanded);
                        }}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    >
                        Expand All
                    </button>
                    <button
                        onClick={() => {
                            // Collapse all shots
                            const allCollapsed = {};
                            episode.processed_shots.forEach((_, index) => {
                                allCollapsed[index] = false;
                            });
                            setExpandedShots(allCollapsed);
                        }}
                        className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                    >
                        Collapse All
                    </button>
                </div>
            </div>
        );
    };

    if (error) return (
        <div className="min-h-screen flex items-center justify-center">
            <div className="bg-red-50 border border-red-200 rounded-md p-6 max-w-lg w-full">
                <h2 className="text-lg font-semibold text-red-700 mb-2">Error</h2>
                <p className="text-red-600">{error}</p>
            </div>
        </div>
    );

    if (!episode) return (
        <div className="min-h-screen flex flex-col items-center justify-center px-4 text-center">
            <p className="text-lg text-gray-700 mb-4 max-w-sm mx-auto">No episode found</p>
        </div>
    );
    
    if (loading) return (
      <div className="min-h-screen flex flex-col items-center justify-center px-4 text-center">
        <p className="text-lg text-gray-700 mb-4 max-w-sm mx-auto">Loading components...</p>
        <LoadingSpinner />
      </div>
    );
    
    if (episode?.status === 'PROCESSING') return (
      <div className="min-h-screen flex flex-col items-center justify-center px-4 text-center">
        <p className="text-lg text-gray-700 mb-4 max-w-sm mx-auto">
          Turning your story into a script. This will take a few minutes...
        </p>
        <LoadingSpinner />
      </div>
    );

    if (episode?.status === 'FIXED_PROCESSED_SHOTS_COMPLETE') return (
      <div className="min-h-screen flex flex-col items-center justify-center px-4 text-center">
        <p className="text-lg text-gray-700 mb-4 max-w-sm mx-auto">
          Creating storyboard images for each line. This will take a few minutes...
        </p>
        <LoadingSpinner />
      </div>
    );

    if (episode?.status === 'FIRST_PASS_PROCESSED_SHOTS_COMPLETE') return (
      <div className="min-h-screen flex flex-col items-center justify-center px-4 text-center">
        <p className="text-lg text-gray-700 mb-4 max-w-sm mx-auto">
          Finding and fixing any errors in the script. This will take a few minutes...
        </p>
        <LoadingSpinner />
      </div>
    );
    
    if (episode.status === 'ERROR') {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="bg-red-50 border border-red-200 rounded-md p-6 max-w-lg w-full">
                    <h2 className="text-lg font-semibold text-red-700 mb-2">Error</h2>
                    <p className="text-red-600">{episode.error || 'An unknown error occurred'}</p>
                </div>
            </div>
        );
    }
    
    if (episode.status !== 'PROCESSED_SHOTS_COMPLETE') {
        return <div>Invalid episode status</div>;
    }
    
    // Then the existing return statement with all the UI
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Text Episode Editor</h1>
            
            {/* Navigation buttons */}
            <div className="flex justify-between items-center mb-4">
                <div className="flex gap-4">
                    <button
                        onClick={() => navigate(`/episode-timeline/${episodeId}`)}
                        className="px-4 py-2 bg-emerald-500 text-white rounded hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-opacity-50"
                    >
                        Compose Episode
                    </button>
                </div>
            </div>

            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
                <h2 className="text-xl font-semibold mb-2">{episode.title}</h2>
                {episode.description && (
                    <div className="mb-4">
                        <h3 className="font-semibold mb-2">Description:</h3>
                        <div className="bg-gray-100 p-4 rounded-lg">
                            <p className="whitespace-pre-line">{episode.description}</p>
                        </div>
                    </div>
                )}

                {renderEpisodeScript()}
                
                {saving && (
                    <div className="mt-4 text-sm text-gray-600">
                        Saving changes...
                    </div>
                )}
            </div>

            <Modal isOpen={!!modalContent} onClose={closeModal}>
                {modalContent}
            </Modal>
            <LivePortraitModal
                isOpen={liveportraitModalVisible}
                onClose={()=>setLiveportraitModalVisible(false)}
                onVideoClick={(videoUrl) => {
                    animateWithLiveportrait(liveportraitInfo.shotIndex, liveportraitInfo.imageKey, liveportraitInfo.imageUrl, videoUrl);
                    setLiveportraitModalVisible(false)
                }}
            />
        </div>
    );
};

export default TextEpisodeViewer;