import React, { useState, useRef } from 'react';
import { LuVolume2, LuVolumeX } from "react-icons/lu";
import VideoModal from "./VideoModal";
import { logEvent, formatTimestamp } from "../utils";
import { useUser } from '../contexts/UserContext';
const VideoCard = ({ video, isLastVideo, lastVideoRef }) => {
  const [showModal, setShowModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null); // null = no video loaded yet
  const videoRef = useRef();
  const { currentUser, loading: userLoading } = useUser();
  const username = currentUser?.email || currentUser?.uid;

  // console.log(video)
  const handleMouseEnter = () => {
    setIsHovered(true);

    // Set the video src to actually load it
    setVideoSrc(video.video_url);

    if (videoRef.current) {
      videoRef.current.muted = false;

      // Always handle the play Promise
      const playPromise = videoRef.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Playback actually started
          })
          .catch((error) => {
            // Autoplay was prevented or playback got interrupted
            console.warn("Video play interrupted:", error);
          });
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);

    if (videoRef.current) {
      // Safely pause first
      videoRef.current.muted = true;
      videoRef.current.pause();
      videoRef.current.currentTime = 0;

      // Optionally reset the video to free buffer
      // videoRef.current.load();

      // Now remove the src from state to show the thumbnail again
      setVideoSrc(null);
    }
  };
  // console.log(video)
 const handleClick = async () => {
    setShowModal(true);
    try {
      await logEvent({
        eventName: "remix-open-public-video",
        user: username,
        video: `https://www.eggnog.ai/mix/${video?.id ?? "unknown"}`,
        template: `http://www.eggnog.ai/remix/${video?.original_scene_id ?? "unknown"}`,
        templateTitle: video?.original_scene_title ?? "Untitled",
      });
    } catch (error) {
      console.error("Failed to log event:", error);
    }
  };  
  
  return (
    <>
      <div
        ref={isLastVideo ? lastVideoRef : null}
        className="bg-gray-900 rounded-lg overflow-hidden cursor-pointer"
        onClick={handleClick}
      >
        {/* -- TOP: Image + Video Overlay -- */}
        <div
          className="relative w-full h-48"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* Thumbnail (always visible) */}
          <img
            src={video.thumbnail_url}
            alt="Video thumbnail"
            className="absolute w-full h-full object-cover"
          />

          {/* Video (hidden by default, fades in on hover) */}
          <video
            ref={videoRef}
            src={video.video_url}
            muted
            loop
            playsInline
            className="absolute w-full h-full object-cover opacity-0 hover:opacity-100 transition-opacity duration-300"
          />
        </div>

        {/* -- BOTTOM: Info Container -- */}
        <div className="p-4">
          <div className="flex justify-between items-start">
            {/* Left-aligned info */}
            <div>
              <p className="text-gray-400 text-left text-xs">
                by {video.creator_name || "Anonymous"}
              </p>
              <p className="text-gray-400 text-left text-xs">
                {video.view_count >= 1000
                  ? `${(video.view_count / 1000).toFixed(1)}k`
                  : video.view_count || 0}{" "}
                views
              </p>
              <p className="text-gray-400 text-left text-xs">
                {formatTimestamp(video.created_at)}
              </p>
            </div>

            {/* Right-aligned 'Remix →' */}
            <p className="font-bold text-green-400 self-end text-xs text-right">
              Remix →
            </p>
          </div>
        </div>
      </div>

      {showModal && <VideoModal video={video} onClose={() => setShowModal(false)} />}
    </>
  );
}

export default VideoCard;

/*
Update to do this overlay so it doesnt flash awkwardly upon mouseover
<div
  className="relative w-full h-48 overflow-hidden"
  onMouseEnter={e => {
    const video = e.currentTarget.querySelector('video');
    if (video) video.play().catch(() => {});
  }}
  onMouseLeave={e => {
    const video = e.currentTarget.querySelector('video');
    if (video) video.pause();
  }}
>
  <img
    src={scene.thumbnail_url}
    alt={scene.title || 'Scene Thumbnail'}
    className="absolute w-full h-full object-cover"
  />
  <video
    src={scene.video_url}
    muted
    loop
    className="absolute w-full h-full object-cover opacity-0 hover:opacity-100 transition-opacity duration-300"
  />
</div>

*/
