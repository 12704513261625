import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInAnonymously } from 'firebase/auth';
import { auth } from '../Firebase.js';
import { useUser } from '../contexts/UserContext';
import { db } from '../Firebase.js';
import {
  collection,
  query,
  orderBy,
  getDocs,
  limit,
  where,
  startAfter,
  onSnapshot,
  doc
} from 'firebase/firestore';
import { useMediaQuery } from 'react-responsive';
import LazyVideo from './LazyVideo';
import InfiniteScroll from 'react-infinite-scroll-component';
import PublicVideos from './PublicVideos';
import SearchBar from './SearchBar';


import { useParams } from 'react-router-dom';
import { getEndpoint } from '../utils';
import RelatedVideos from './RelatedVideos';

import { logEvent } from "../utils";


function SceneLibrary() {
  const [displayedScenes, setDisplayedScenes] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useUser();
  const username = currentUser?.email || currentUser?.uid;
  const ITEMS_PER_LOAD = 20;
  
  const searchTimeoutRef = useRef(null);
  const lastDocRef = useRef(null);
  const observerRef = useRef();

  const fetchScenes = async (searchValue = '') => {
    try {
      setLoading(true);
      let baseQuery = collection(db, 'remix-scenes-stock');
      
      if (searchValue.length >= 3) {
        baseQuery = query(
          baseQuery,
          where('search_terms', 'array-contains', searchValue.toLowerCase()),
          orderBy('timestamp', 'desc'),
          limit(ITEMS_PER_LOAD + 1)
        );
      } else {
        baseQuery = query(
          baseQuery,
          orderBy('timestamp', 'desc'),
          limit(ITEMS_PER_LOAD + 1)
        );
      }

      const querySnapshot = await getDocs(baseQuery);
      const allDocs = querySnapshot.docs;
      const scenesData = allDocs
        .slice(0, ITEMS_PER_LOAD)
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter(scene => !scene.hidden);

      setDisplayedScenes(scenesData);
      setHasMore(allDocs.length > ITEMS_PER_LOAD);
      lastDocRef.current = allDocs.length > ITEMS_PER_LOAD ? allDocs[ITEMS_PER_LOAD - 1] : null;
    } catch (error) {
      console.error('Error fetching scenes:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value) => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      setSearchTerm(value);
      lastDocRef.current = null;
      setDisplayedScenes([]);
      setHasMore(true);
      fetchScenes(value);
    }, 300);
  };

  const fetchMoreData = async () => {
    if (!hasMore || !lastDocRef.current || loading) return;

    try {
      setLoading(true);
      let baseQuery = collection(db, 'remix-scenes-stock');
      
      if (searchTerm.length >= 3) {
        baseQuery = query(
          baseQuery,
          where('search_terms', 'array-contains', searchTerm.toLowerCase()),
          orderBy('timestamp', 'desc'),
          startAfter(lastDocRef.current),
          limit(ITEMS_PER_LOAD + 1)
        );
      } else {
        baseQuery = query(
          baseQuery,
          orderBy('timestamp', 'desc'),
          startAfter(lastDocRef.current),
          limit(ITEMS_PER_LOAD + 1)
        );
      }

      const querySnapshot = await getDocs(baseQuery);
      const allDocs = querySnapshot.docs;
      const newScenes = allDocs
        .slice(0, ITEMS_PER_LOAD)
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter(scene => !scene.hidden);

      setDisplayedScenes(prev => [...prev, ...newScenes]);
      setHasMore(allDocs.length > ITEMS_PER_LOAD);
      lastDocRef.current = allDocs.length > ITEMS_PER_LOAD ? allDocs[ITEMS_PER_LOAD - 1] : null;
    } catch (error) {
      console.error('Error fetching more scenes:', error);
    } finally {
      setLoading(false);
    }
  };

  const lastSceneRef = useCallback(
    (node) => {
      if (loading) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchMoreData();
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [loading, hasMore, searchTerm]
  );

  useEffect(() => {
    fetchScenes();
  }, []);

  const isNew = (timestamp) => {
    const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;
    const sceneDate = timestamp.toDate();
    const now = new Date();
    return now - sceneDate < threeDaysInMs;
  };

  return (
    <>
      <SearchBar onSearch={handleSearch} />
      <p className="text-xl font-bold text-gray-300 mb-4">Scene Library</p>
      
      {searchTerm.length > 0 && searchTerm.length < 3 && (
        <div className="flex flex-col items-center justify-center p-8 text-center">
          <p className="text-gray-400">
            Please enter at least 3 characters to search
          </p>
        </div>
      )}
      
      {!loading && displayedScenes.length === 0 && searchTerm.length >= 3 && (
        <div className="flex flex-col items-center justify-center p-8 text-center">
          <p className="text-gray-400">
            No scenes found matching "{searchTerm}"
          </p>
        </div>
      )}

      {(displayedScenes.length > 0 || loading) && (
        <div className="grid grid-cols-1 2xl:grid-cols-3 gap-6">
          {displayedScenes.map((scene, index) => (
            <div
              ref={index === displayedScenes.length - 1 ? lastSceneRef : null}
              key={scene.id}
            >
              <LazyVideo
                thumbnailUrl={scene.thumbnail_url}
                videoUrl={scene.video_url}
                title={scene.title}
                usageCount={scene.usage_count}
                isNew={isNew(scene.timestamp)}
                onClick={async () => {
                  navigate(`/remix/${scene.id}`);
                  console.log('Logging event:', {
                    eventName: 'remix-click-homepage-thumbnail',
                    sceneId: scene.id,
                    title: scene.title,
                    user: username,
                  });
                }}
              />
            </div>
          ))}
        </div>
      )}

      {loading && (
        <div className="flex justify-center items-center p-4">
          <div className="w-10 h-10 rounded-full border-4 border-gray-700 border-t-green-400 animate-[spin_0.8s_ease-in-out_infinite]"></div>
        </div>
      )}

      {!hasMore && displayedScenes.length > 0 && !loading && (
        <p className="text-center text-gray-400 my-8">
          <b>No more scenes to display</b>
        </p>
      )}
    </>
  );
}


const RemixEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const videoRef = React.useRef(null);
  const { currentUser } = useUser();
  const username = currentUser?.email || currentUser?.uid;
  const [scene, setScene] = useState(null);
  const [lines, setLines] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [docExists, setDocExists] = useState(false);

  const CHARACTER_LIMIT = 200;

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, 'remix-scenes-stock', id),
      (docSnap) => {
        setLoading(false);

        if (!docSnap.exists()) {
          setDocExists(false);
          return;
        }

        setDocExists(true);
        const sceneData = { id: docSnap.id, ...docSnap.data() };
        setScene(sceneData);

        if (sceneData.scene) {
          const initialLines = sceneData.scene.map((segment) => ({
            speaker_id: segment.speaker_id,
            duration: segment.end - segment.start,
            original_text: segment.original_text,
            text: '',
          }));
          setLines(initialLines);
        }
      },
      (error) => {
        console.error('Firestore onSnapshot error:', error);
        setLoading(false);
        setDocExists(false);
      }
    );

    return () => unsubscribe();
  }, [id]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await logEvent({
        eventName: 'remix-submit-remix',
        sceneId: scene.id,
        title: scene.title,
        user: username,
      });

      const response = await fetch(`${getEndpoint()}/remix-scene`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          scene_document: scene,
          lines: lines,
        }),
      });

      if (response.ok) {
        const { document_id } = await response.json();
        navigate(`/mix/${document_id}`);
      }
    } catch (error) {
      console.error('Error submitting remix:', error);
    }
    setIsSubmitting(false);
  };

  const getPlaceholder = (line) => {
    if (line.original_text) {
      return `${line.original_text.slice(
        0,
        100 + line.original_text.slice(100).indexOf(' ')
      )}...`;
    } else {
      return 'Enter text';
    }
  };

  const handleTextChange = (index, value) => {
    const newLines = [...lines];
    // Limit the text to CHARACTER_LIMIT characters
    newLines[index].text = value.slice(0, CHARACTER_LIMIT);
    setLines(newLines);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-black flex flex-col items-center justify-center gap-4">
        <p className="text-gray-400 text-lg">Loading…</p>
      </div>
    );
  }

  if (!docExists) {
    return (
      <div className="min-h-screen bg-black flex flex-col items-center justify-center gap-4">
        <p className="text-gray-400 text-lg">
          Hm, we couldn't find a remix with that ID
        </p>
        <a
          href="https://www.eggnog.ai/remix"
          className="text-green-400 hover:text-green-300 transition-colors"
        >
          Go home
        </a>
      </div>
    );
  }

  if (!scene?.scene) {
    return (
      <div className="min-h-screen bg-black flex flex-col items-center justify-center gap-4">
        <p className="text-gray-400 text-lg">Hm, we couldn't find a remix</p>
        <a
          href="https://www.eggnog.ai/remix"
          className="text-green-400 hover:text-green-300 transition-colors"
        >
          Go home
        </a>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white">
      <header className="sticky top-0 bg-black/90 backdrop-blur-sm p-4 z-10">
        <h1 className="text-2xl font-bold text-center">🥚 remix</h1>
      </header>

      <div className="max-w-4xl mx-auto p-4">
        <button
          onClick={() => navigate('/remix')}
          className="text-gray-400 hover:text-white transition my-4"
        >
          ← Back to all scenes
        </button>
        <div className="mb-8">
          <video
            ref={videoRef}
            src={scene.video_url}
            controls
            autoPlay
            onLoadedData={() => {
              if (videoRef.current) {
                videoRef.current.volume = 1;
              }
            }}
            className="w-full rounded-lg shadow-lg border-2 border-gray-700"
          />
        </div>
        <h1 className="text-xl font-bold my-4">New Remix script</h1>
        <div className="space-y-4">
          {lines.map((line, index) => (
            <div
              key={index}
              className="bg-gray-900 p-4 rounded-lg border border-gray-800 transition-all hover:border-gray-700"
            >
              <div className="flex justify-between mb-2">
                <span className="text-gray-400">
                  {line.speaker_id
                    .split(/[_-]/)
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(' ')}
                </span>
                <span className="text-gray-400">{line.duration.toFixed(1)}s</span>
              </div>
              <input
                type="text"
                value={line.text}
                onChange={(e) => handleTextChange(index, e.target.value)}
                className="w-full p-3 rounded-lg bg-gray-800 border border-gray-700 focus:border-gray-500 focus:outline-none transition"
                placeholder={getPlaceholder(line)}
              />
              {line.text.length >= CHARACTER_LIMIT && (
                <p className="text-red-500 text-xs mt-1">Maximum character limit reached</p>
              )}
            </div>
          ))}
        </div>

        <button
          onClick={handleSubmit}
          disabled={isSubmitting || lines.some(line => !line.text)}
          className="my-6 w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 disabled:bg-gray-700 disabled:cursor-not-allowed transition-colors duration-200 font-bold"
        >
          {isSubmitting ? 'Creating...' : 'Create Remix'}
        </button>
      <RelatedVideos originalSceneId={id} videoId={''} originalSceneTitle={scene.title} showMakeOne={false}/>
      </div>
    </div>
  );
};

// import React, { useState, useEffect } from 'react';
// import { useMediaQuery } from 'react-responsive';
// import { signInAnonymously } from 'firebase/auth';
// import { auth } from '../Firebase.js';
// import { useUser } from '../contexts/UserContext';
// import PublicVideos from './PublicVideos';
// import { SceneLibrary } from './SceneLibrary';

export function RemixHome() {
  const [activeTab, setActiveTab] = useState('recentlyCreated');
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  const { currentUser, loading: userLoading } = useUser();

  useEffect(() => {
    document.title = 'Eggnog Remix';
    const checkAndSignInAnonymously = async () => {
      if (!currentUser && !userLoading) {
        try {
          await signInAnonymously(auth);
        } catch (error) {
          console.error('Error during anonymous sign-in:', error);
        }
      }
    };
    checkAndSignInAnonymously();
  }, [currentUser, userLoading]);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      <header className="sticky top-0 bg-black/90 backdrop-blur-sm p-4 z-10">
        <h1 className="text-2xl font-bold text-center">🥚 remix </h1>
        <h2 className="text-md text-center text-gray-400 mt-2">
          Remix popular movie scenes, TV shows, and current events ✨
        </h2>
      </header>

      {isDesktopOrLaptop ? (
        <div className="flex flex-1">
          <div className="w-64 flex flex-col items-start p-4">
            <p className="text-gray-400 text-left px-4 py-2 text-sm">
              Eggnog Remix
            </p>
            <button
              className={`w-full text-left py-3 px-4 rounded-lg mb-2 ${
                activeTab === 'recentlyCreated'
                  ? 'bg-green-600 text-white font-bold'
                  : 'text-gray-400 hover:bg-gray-700'
              }`}
              onClick={() => setActiveTab('recentlyCreated')}
            >
              Recently Created
            </button>
            <button
              className={`w-full text-left py-3 px-4 rounded-lg ${
                activeTab === 'sceneLibrary'
                  ? 'bg-green-600 text-white font-bold'
                  : 'text-gray-400 hover:bg-gray-700'
              }`}
              onClick={() => setActiveTab('sceneLibrary')}
            >
              Scene Library
            </button>
          </div>

          <div className="w-5/6 flex-1 flex justify-center p-4">
            <div className="w-3/4">
              {activeTab === 'recentlyCreated' && <PublicVideos />}
              {activeTab === 'sceneLibrary' && <SceneLibrary />}
            </div>
          </div>

          <div className="w-64 flex flex-col items-start p-4">
            {/* Placeholder for maintaining layout */}
          </div>
        </div>
      ) : (
        <div className="flex flex-col flex-1">
          <div className="flex justify-center font-bold">
            <button
              className={`flex-1 py-3 text-center ${
                activeTab === 'recentlyCreated'
                  ? 'text-green-400 border-b-2 border-green-400 font-bold'
                  : 'text-gray-400 hover:text-white'
              }`}
              onClick={() => setActiveTab('recentlyCreated')}
            >
              Recently Created
            </button>
            <button
              className={`flex-1 py-3 text-center ${
                activeTab === 'sceneLibrary'
                  ? 'text-green-400 border-b-2 border-green-400 font-bold'
                  : 'text-gray-400 hover:text-white'
              }`}
              onClick={() => setActiveTab('sceneLibrary')}
            >
              Scene Library
            </button>
          </div>
          <div className="flex-1 p-4">
            {activeTab === 'recentlyCreated' && <PublicVideos />}
            {activeTab === 'sceneLibrary' && <SceneLibrary />}
          </div>
        </div>
      )}

      <footer className="mt-auto m-2">
        <div className="mx-auto px-4 py-4 flex justify-center text-gray-400">
          <a
            href="https://twitter.com/eggnog_ai"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-gray-300 transition-colors flex items-center gap-2 mx-2"
          >
            <span>Twitter</span>
          </a>
          <a
            href="https://twitter.com/eggnog_ai"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-gray-300 transition-colors flex items-center gap-2 mx-2"
          >
            <span>Discord</span>
          </a>
        </div>
      </footer>
    </div>
  );
}
// Main Component with Updated Routing
const SceneRemix = () => {
  const { id } = useParams();
  
  return id ? <RemixEditor /> : <RemixHome />;
};


export default SceneRemix;